import downArrow from '@/assets/whiteDownload.svg'
import { Button } from '@/components'
import InvoiceReminder from '@/components/Modals/Invoice/InvoiceReminder'
import RecordPayment from '@/components/Modals/Invoice/RecordPaymentModal'
import StatusColor from '@/components/utils/StatusColor'
import { useInvoiceContext } from '@/context'
import { useGetPossibleTransactionMatch } from '@/services/invoice/queries'
import { formatAmountNoSymbol } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import InvoiceAmountTable from '../../components/InvoiceAmountTable'
import MatchInvoiceTransactions from '@/components/Modals/Invoice/InvoiceMatching/MatchInvoiceTransactions'
import LinkedTransactions from '../../components/LinkedTransactions'
import InvoicePermissionsWrapper from '../InvoiceAnalytics/InvoicePermissionsWrapper'
import { SendReminder } from '@/assets/assets.export'
import { Invoice, InvoiceStatus } from '@/types/invoice'

type Props = {
    invoiceDetails?: Invoice
}
export default function InvoiceDetails({ invoiceDetails }: Props) {
    const { setEditModal } = useInvoiceContext()
    const { invoice_id } = useParams()
    const [recordPayment, setRecordPayment] = useState(false)
    const [resendInvoice, setResendInvoice] = useState(false)
    const [openMatchedTransactionModal, setOpenMatchedTransactionModal] = useState(false)

    const { data } = useGetPossibleTransactionMatch({
        queryParams: {
            invoice_id: invoice_id as string,
        },
    })

    console.log(invoiceDetails?.paid)
    const status = invoiceDetails?.status === InvoiceStatus.SENT || invoiceDetails?.status === InvoiceStatus.OVERDUE
    const matchedTransactions = useMemo(() => data ?? [], [data])

    return (
        <div>
            <div className='bg-[#F9F9F9] py-[30px] px-[20px] lg:px-[40px] mb-[80px]'>
                <>
                    <div className='bg-white px-5 py-4 mb-4 rounded-xl'>
                        <div className='flex justify-between'>
                            {Number(matchedTransactions?.length) > 0 && (
                                <div className='flex items-center justify-between  gap-6 text-black/80 text-sm font-medium border bg-errorRed/10 border-errorRed rounded-lg p-3 py-1.5'>
                                    <p>
                                        {matchedTransactions?.length > 1 ? ' Multiple payments' : 'Payment'} detected!{' '}
                                        {matchedTransactions?.length}{' '}
                                        {matchedTransactions?.length > 1 ? 'transactions' : 'transaction'} seem to match
                                        this Invoice
                                    </p>
                                    <Button
                                        onClick={() => setOpenMatchedTransactionModal(true)}
                                        className='bg-black/80 hover:bg-black px-2.5 min-w-[100px] min-h-[25px] py-0 text-xs'
                                    >
                                        Please review to confirm
                                    </Button>
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col gap-4 text-[#202020]'>
                            <h1 className='text-lg font-semibold border-b border-[#DADCE0] py-2 flex justify-between'>
                                {invoiceDetails?.invoice_id} - {invoiceDetails?.currency?.symbol ?? '₦'}
                                {formatAmountNoSymbol(Number(invoiceDetails?.total_amount))}
                                {status && (
                                    <button
                                        onClick={() => setResendInvoice(true)}
                                        className='text-brand text-xs flex items-center gap-1'
                                    >
                                        Send Reminder <SendReminder />
                                    </button>
                                )}
                            </h1>

                            <div className='flex justify-between flex-col-reverse lg:flex-row gap-4 lg:gap-0'>
                                <div className='w-full lg:w-[70%]'>
                                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4 gap-y-6 text-base mb-12'>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Invoice ID</h2>
                                            <p>{invoiceDetails?.invoice_id}</p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Customer</h2>
                                            <p>{invoiceDetails?.customer?.name}</p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Issue date</h2>
                                            <p>
                                                {invoiceDetails?.issue_date &&
                                                    format(new Date(invoiceDetails?.issue_date), 'dd/MM/yyyy')}
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Due date</h2>
                                            <p>
                                                {invoiceDetails?.issue_date &&
                                                    format(new Date(invoiceDetails?.due_date), 'dd/MM/yyyy')}
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Total Amount</h2>
                                            <p className=''>
                                                <span className='font-medium'>
                                                    {invoiceDetails?.currency?.symbol ?? '₦'}
                                                </span>
                                                {formatAmountNoSymbol(Number(invoiceDetails?.total_amount))}
                                            </p>
                                        </div>
                                        {invoiceDetails?.status === 'PAID' && (
                                            <div className=''>
                                                <h2 className='font-semibold mb-2'>Paid Amount</h2>
                                                <p className=''>
                                                    <span className='font-medium'>
                                                        {invoiceDetails?.currency?.symbol ?? '₦'}
                                                    </span>
                                                    {formatAmountNoSymbol(Number(invoiceDetails?.metadata?.amount))}
                                                </p>
                                            </div>
                                        )}
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Status</h2>

                                            <p className=''>
                                                <StatusColor
                                                    disableBgColor={true}
                                                    status={invoiceDetails?.status.toLowerCase() as string}
                                                />
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Created by</h2>
                                            <div className='w-[100%]'>
                                                <p>
                                                    {invoiceDetails?.issuer?.user?.first_name}{' '}
                                                    {invoiceDetails?.issuer?.user?.last_name}
                                                </p>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Date Created</h2>
                                            <p>
                                                {invoiceDetails?.createdAt &&
                                                    format(new Date(invoiceDetails?.createdAt), 'dd/MM/yyyy')}
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Payment method</h2>
                                            <p>
                                                {capitalizeText(invoiceDetails?.payment_method)}
                                                {invoiceDetails?.payment_method === 'BOTH' ? ' (online & offline)' : ''}
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Amount paid</h2>
                                            <p>
                                                <span className='font-medium'>
                                                    {invoiceDetails?.currency?.symbol ?? '₦'}
                                                </span>
                                                {formatAmountNoSymbol(Number(invoiceDetails?.paid))}
                                            </p>
                                        </div>
                                    </div>

                                    <InvoicePermissionsWrapper
                                        setEditModal={setEditModal}
                                        setRecordPayment={setRecordPayment}
                                        invoice={invoiceDetails}
                                    />
                                </div>
                                <div className=''>
                                    <Link
                                        className='relative'
                                        to={invoiceDetails?.logo as string}
                                        target='_blank'
                                        download
                                    >
                                        <img
                                            alt='image logo'
                                            src={invoiceDetails?.logo}
                                            className='object-cover w-[123px] h-[123px] rounded-lg inline-flex'
                                        />
                                        <img
                                            src={downArrow}
                                            alt='button'
                                            className='absolute -bottom-11 right-[13px] bg-[#5E5E5ED4] rounded-md p-1 cursor-pointer text-[#19C37D]/90'
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='p-5 bg-white rounded-xl overflow-y-scroll'>
                        <InvoiceAmountTable invoiceDetails={invoiceDetails} />
                    </div>
                    <div className='mt-4 p-5 bg-white rounded-xl overflow-y-scroll'>
                        <h2>Linked transaction(s)</h2>
                        <LinkedTransactions invoice={invoiceDetails} transactionsData={invoiceDetails?.transactions} />
                    </div>
                </>

                {openMatchedTransactionModal && (
                    <MatchInvoiceTransactions
                        invoice={invoiceDetails}
                        matchedTransactions={matchedTransactions}
                        closeModal={() => setOpenMatchedTransactionModal(false)}
                    />
                )}
                {recordPayment && <RecordPayment closeModal={setRecordPayment} invoiceDetails={invoiceDetails!} />}
                {resendInvoice && <InvoiceReminder closeModal={setResendInvoice} invoice={invoiceDetails!} />}
            </div>
        </div>
    )
}
