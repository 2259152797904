export const VaultStorage = () => {
    return (
        <div>
            <h1 className='text-dark font-medium text-lg'>Storage plan & usage</h1>
            <p className='text-gray7 text-sm'>Track usage, upgrade storage, and optimize your document management</p>
            <div className='mt-4'>
                <div className='bg-[#F9F9F9] p-4 flex flex-col gap-3 rounded-xl'>
                    <div className='font-medium text-gray7 flex items-center justify-between text-sm'>
                        <p>65.6 GB of 100 GB used</p>
                        <p>20.5 GB available</p>
                    </div>
                    <div className='h-11 bg-white rounded-lg mb-6'></div>
                    <div className='font-medium text-gray7 text-sm flex items-center gap-8 flex-wrap'>
                        <div className='flex items-center gap-2'>
                            <p className='h-4 w-4 bg-[#DEB145] rounded'></p>
                            <p>PNG/JPEGS</p>
                        </div>
                        <div className='flex items-center gap-2'>
                            <p className='h-4 w-4 bg-[#FF9152] rounded'></p>
                            <p>PDFs</p>
                        </div>
                        <div className='flex items-center gap-2'>
                            <p className='h-4 w-4 bg-[#5C9BFF] rounded'></p>
                            <p>XLS/CSV</p>
                        </div>
                        <div className='flex items-center gap-2'>
                            <p className='h-4 w-4 bg-[#454ADE] rounded'></p>
                            <p>Texts</p>
                        </div>
                    </div>
                </div>
                <button className='flex mt-6 items-center w-fit gap-2 border border-[#454ADE] px-3 py-2 rounded-lg bg-[#EFEFF9] text-brand text-sm font-semibold'>
                    Change storage plan
                </button>
            </div>
            <div className='bg-[#F9F9F9] p-4 flex flex-col gap-4 mt-7 rounded-xl'>
                {cats.map(category => (
                    <div className='font-medium text-gray7 flex items-center justify-between'>
                        <p>{category.name}</p>
                        <p>{category.amount} GB </p>
                    </div>
                ))}
            </div>
        </div>
    )
}

const cats = [
    {
        name: 'Receipts',
        amount: 20.5,
    },
    {
        name: 'Invoices',
        amount: 9.5,
    },
    {
        name: 'SLAs',
        amount: 6.11,
    },
    {
        name: 'Contracts',
        amount: 4.9,
    },
    {
        name: 'Bills',
        amount: 3.12,
    },
]
