import vaultImg from '@/assets/v2dashboard/vault-init.svg'
import { Button } from '@/components'
import { CreateVault } from '@/components/Modals/Vault/CreateVault'
import { useState } from 'react'

export const NoVaultComponent = () => {
    const [createVault, setCreateVault] = useState(false)

    return (
        <div className='flex items-center justify-center flex-col gap-5 h-full'>
            <img src={vaultImg} alt='' className='mt-10 mb-5' />
            <p className='text-gray7 font-semibold text-[20px] mb-5 max-w-[415px] text-center'>
                You have not activated vault yet. Kindly click the button below to start storing files and folders here
            </p>
            <Button onClick={() => setCreateVault(true)}>Activate vault</Button>
            {createVault && <CreateVault closeModal={setCreateVault} />}
        </div>
    )
}
