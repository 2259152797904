import emptyWallet from '@/assets/empty-wallet.svg'
import { OverAPeriodIcon } from '@/assets/assets.export'
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useEffect, useMemo, useState } from 'react'

import { useAuthContext } from '@/context'
import ProgressBar from '@/components/ProgressBar'
import { formatAmount } from '@/utils/money'
import { CustomBarTooltip } from '@/components/Tooltips/CustomTooltip'
import { useGetInvoiceByMonth, useGetInvoiceSummary, useGetTopInvoiceCustomers } from '@/services/invoice/queries'
import TopCustomersTable from '../../components/TopCustomersTable'
import { useSearchParams } from 'react-router-dom'

export type CustomTooltipProps = {
    active?: any
    payload?: any
    label?: any
}

const InvoiceAnalytics = () => {
    const { selectedAccount } = useAuthContext()
    const thisYear = new Date().getFullYear()
    const [invoiceYear, setInvoiceYear] = useState(thisYear + '')
    const [summaryYear, setSummaryYear] = useState(thisYear + '')
    const [topPayingCustomersYear, setTopPayingCUstomersYear] = useState(thisYear + '')
    const [searchParams] = useSearchParams()

    const currency = searchParams.get('currency') as string

    const {
        data,
        isLoading: isLoadingInvoiceSummary,
        refetch: refetchInvoiceSummary,
        isRefetching: refetchingSummary,
    } = useGetInvoiceSummary({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            year: summaryYear.toString() as string,
            currency,
        },
    })
    const {
        data: topInvoiceCustomers,
        isLoading,
        refetch,
    } = useGetTopInvoiceCustomers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            year: topPayingCustomersYear.toString() as string,
            currency,
        },
    })
    const {
        data: invoicesByMonth,
        refetch: refetchInvoicesByMonth,
        isLoading: isLoadingInvoicesByMonth,
        isRefetching: isRefetchingInvoicesByMonth,
    } = useGetInvoiceByMonth({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            year: invoiceYear as string,
            currency,
        },
    })

    const topCustomers = useMemo(() => topInvoiceCustomers, [topInvoiceCustomers])
    const invoiceSummary = useMemo(() => data, [data])
    const invoiceMonths = useMemo(() => invoicesByMonth, [invoicesByMonth])

    const totalAmount = invoiceMonths?.reduce(
        (accumulator, currentValue) => accumulator + parseFloat(currentValue.total),
        0
    )

    useEffect(() => {
        refetchInvoiceSummary()
    }, [summaryYear, currency])

    useEffect(() => {
        refetchInvoicesByMonth()
    }, [invoiceYear, currency])

    useEffect(() => {
        refetch()
    }, [topPayingCustomersYear, currency])

    if (isLoadingInvoiceSummary || isLoading || isLoadingInvoicesByMonth) return <ProgressBar />

    // const percentageIcon = (Number(invoiceSummary?.total_payments_received) / Number(100000000)) * 100 //calculations here are in kobo

    return (
        <div className='w-full py-6 block pb-28'>
            {/* <div className='flex items-center justify-end mb-6'>
                <div className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'>
                    <img src={downArrow} alt='button' />
                </div>
            </div> */}

            <section className='p-4 border border-[#DADCE0] rounded mb-6'>
                <div className='flex justify-end mb-6'>
                    <div>
                        <Select name='invoiceYear' onValueChange={setSummaryYear} value={summaryYear}>
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[8rem]'>
                                <SelectItem value={`${thisYear - 1}`}>Last year </SelectItem>
                                <SelectItem value={`${thisYear}`}>This year</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className={`grid grid-cols-1 lg:grid-cols-7 gap-[20px] ${refetchingSummary ? 'opacity-30' : ''}`}>
                    <div className='lg:col-span-3 border border-[#DADCE0] bg-[#EFEFF9] rounded p-6 '>
                        {/* <div className='flex justify-between items-center mb-6'>
                            <span className='text-[#5e5e5e] text-sm'>
                                Received
                                <span className='text-[#19C37D]  '> 15% </span>
                                more than previous year ({summaryYear})
                            </span>
                        </div> */}
                        <div className=''>
                            <img src={emptyWallet} alt='' className='h-10' />
                        </div>
                        <div className='mt-8'>
                            <div className='font-semibold text-lg flex justify-between mb-4 text-[#5E5E5E]'>
                                Total payments received
                            </div>
                            <p className='text-3xl lg:text-[40px] text-[#202020] font-extrabold'>
                                {formatAmount(invoiceSummary?.total_payments_received ?? 0)}
                            </p>
                        </div>
                    </div>

                    <div className='lg:col-span-4 grid grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-[20px] text-base'>
                        <div className='border border-[#DADCE0] bg-[#F4FAFF] rounded pl-5 p-3 lg:p-6 flex flex-col justify-between gap-2 lg:gap-0'>
                            <span className='text-[#5E5E5E]'>Total invoices</span>
                            <span className='text-xl lg:text-2xl font-medium '>
                                {invoiceSummary?.total_invoices ?? 0}
                            </span>
                        </div>

                        <div className='border border-[#DADCE0] bg-[#FFFFF5] rounded pl-5 p-3 lg:p-6 flex flex-col justify-between gap-2 lg:gap-0'>
                            <span className='text-[#5E5E5E]'>Pending</span>
                            <span className='text-xl lg:text-2xl font-medium '>{invoiceSummary?.pending ?? 0}</span>
                        </div>

                        <div className='border border-[#DADCE0] bg-[#FFEFE7] rounded pl-5 p-3 lg:p-6 flex flex-col justify-between gap-2 lg:gap-0'>
                            <span className='text-[#5E5E5E]'>Paid</span>
                            <span className='text-xl lg:text-2xl font-medium '>{invoiceSummary?.paid ?? 0}</span>
                        </div>
                        <div className='border border-[#DADCE0] bg-[#F4FFFA] rounded pl-5 p-3 lg:p-6 flex flex-col justify-between gap-2 lg:gap-0'>
                            <span className='text-[#5E5E5E]'>Unpaid</span>
                            <span className='text-xl lg:text-2xl font-medium '>{invoiceSummary?.unpaid ?? 0}</span>
                        </div>
                        <div className='border border-[#DADCE0] bg-[#EBF5FF] rounded pl-5 p-3 lg:p-6 flex flex-col justify-between gap-2 lg:gap-0'>
                            <span className='text-[#5E5E5E]'>Drafts</span>
                            <span className='text-xl lg:text-2xl font-medium '>{invoiceSummary?.drafts ?? 0}</span>
                        </div>
                    </div>
                </div>
            </section>

            <section className='p-4 pt-6 border border-[#DADCE0] rounded mb-6'>
                <div className='flex items-center justify-between mb-6'>
                    <span className='font-semibold w-[250px] md:w-auto'>Invoice payment received by time period</span>
                    <div>
                        <Select name='invoiceYear' onValueChange={setInvoiceYear} value={invoiceYear}>
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[8rem]'>
                                <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                                <SelectItem value={`${thisYear}`}>This year</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className={`${isRefetchingInvoicesByMonth ? 'opacity-30' : ''}`}>
                    {/*==== Bar chart ====*/}
                    {totalAmount! > 0 ? (
                        <div className={`bg-white p-4 rounded-md`}>
                            <ResponsiveContainer height={350} width={'100%'} className={'mx-auto'}>
                                <BarChart data={invoiceMonths!} margin={{ left: 60 }}>
                                    <CartesianGrid
                                        stroke='#DADCE0'
                                        vertical={false}
                                        strokeDasharray='5 5'
                                        fill='white'
                                    />
                                    <XAxis
                                        dataKey='name'
                                        stroke='#888888'
                                        fontSize={12}
                                        tickLine={false}
                                        axisLine={false}
                                    />
                                    <YAxis
                                        dataKey='total'
                                        stroke='#888888'
                                        fontSize={12}
                                        tickLine={false}
                                        axisLine={false}
                                        tickFormatter={value => formatAmount(value)}
                                        hide={false}
                                        label={{
                                            value: 'Invoice payments in Naira',
                                            angle: -90,
                                            position: 'insideLeft',
                                            offset: -50,
                                        }}
                                        offset={10}
                                        // domain={[0, 'dataMax + 1']}
                                        type='number'
                                        // domain={[0, 'dataMax + 1']}
                                    />
                                    <Bar dataKey='total' fill='#454ADE' radius={[4, 4, 0, 0]} barSize={50} />
                                    <Tooltip
                                        filterNull={true}
                                        cursor={false}
                                        wrapperStyle={{ borderRadius: '15px', zIndex: '10' }}
                                        content={<CustomBarTooltip />}
                                    />
                                    <ReferenceLine y={0} stroke='#DADCE0' type='linear' />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    ) : (
                        <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                            <OverAPeriodIcon />
                            <p className='text-sm mt-6'>No payments have been made yet</p>
                        </div>
                    )}
                </div>
            </section>

            <section className='p-4 border border-[#DADCE0] rounded-lg'>
                <div className='flex items-center justify-between mb-6'>
                    <div className='flex items-center gap-6'>
                        <label className='font-medium'>Top paying customers:</label>
                    </div>

                    <div>
                        <Select onValueChange={setTopPayingCUstomersYear} value={topPayingCustomersYear}>
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[8rem]'>
                                <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                                <SelectItem value={`${thisYear}`}>This year</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className='rounded-lg overflow-y-scroll'>
                    <TopCustomersTable customers={topCustomers!} />
                </div>
            </section>
        </div>
    )
}

// dummy data
export const chartData = [
    {
        name: 'Jan',
        total: '580000',
    },
    {
        name: 'Feb',
        total: '590000',
    },
    {
        name: 'Mar',
        total: '500000',
    },
    {
        name: 'Apr',
        total: '600500',
    },
    {
        name: 'May',
        total: '400000',
    },
    {
        name: 'Jun',
        total: '300000',
    },
    {
        name: 'Jul',
        total: '300500',
    },
    {
        name: 'Aug',
        total: '400500',
    },
    {
        name: 'Sep',
        total: '700200',
    },
    {
        name: 'Oct',
        total: '600300',
    },
    {
        name: 'Nov',
        total: '500700',
    },
    {
        name: 'Dec',
        total: '900000',
    },
]

export default InvoiceAnalytics
