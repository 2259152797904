import { Select, SelectContent, SelectTrigger, SelectItem, SelectValue, Input, Button } from '@/components'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { AddOfficerComplianceModal } from '@/components/Modals/Compliance/AddOfficerComplianceModal'
import { Fragment, useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { CgClose } from 'react-icons/cg'
import { UploadKYBDocument } from './UploadKYBDocument'
import { BizCompanyComplianceType, officersEmptyState } from '../utils'
import { toastHandler } from '@/components/utils/Toast'
import { useCompleteCompanyKYB } from '@/services/auth/mutations'
import { useNavigate } from 'react-router-dom'
import { ErrorText } from './ErrorText'

enum COMPANY_TYPE {
    'SOLE_PROPRIETORSHIP' = 'SOLE_PROPRIETORSHIP',
    'NGO' = 'NGO',
    'PUBLIC_LIMITED_COMPANY' = 'PUBLIC_LIMITED_COMPANY',
    'PRIVATE_LIMITED_COMPANY' = 'PRIVATE_LIMITED_COMPANY',
}
enum ROLE {
    OWNER = 'OWNER',
    DIRECTOR = 'DIRECTOR',
}

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>
}

export const IncorporationInfo = ({ setStep }: Props) => {
    const navigate = useNavigate()
    const { control, watch, trigger, getValues } = useFormContext<BizCompanyComplianceType>()
    const [openOfficerModal, setOpenOfficerModal] = useState(false)
    const [editOfficerModal, setEditOfficerModal] = useState(false)

    const { mutate: completeKYBFn, isLoading } = useCompleteCompanyKYB({
        onSuccess: () => {
            navigate('/dashboard/settings/company', { replace: true })
            toastHandler({ message: 'KYB submitted', state: 'success' })
        },
    })

    const { fields, append, remove } = useFieldArray({
        control: control,
        name: 'officers',
    })

    const [fieldIdx, setFieldIdx] = useState(fields.length)
    const calendarRef1 = useRef<ReactDatePicker>(null)

    const watchCompanyType = watch('company_type') as COMPANY_TYPE
    const watchOfficers = watch('officers')

    const handleSubmit = async () => {
        const formValues = getValues()

        const owners = watchOfficers.filter(officer => officer.data.role === ROLE.OWNER)
        const directors = watchOfficers.filter(officer => officer.data.role === ROLE.DIRECTOR)
        if (watchCompanyType === COMPANY_TYPE.SOLE_PROPRIETORSHIP && owners.length < 1) return
        if (watchCompanyType !== COMPANY_TYPE.SOLE_PROPRIETORSHIP && owners.length < 1 && directors.length < 1) return

        let isValid = await trigger(['company_type', 'registration_date', 'proof_of_address'])

        switch (watchCompanyType) {
            case COMPANY_TYPE.NGO:
                isValid = await trigger(['cac_it_number', 'cac_it_form', 'scuml_certificate'])
                break
            case COMPANY_TYPE.PRIVATE_LIMITED_COMPANY:
            case COMPANY_TYPE.PUBLIC_LIMITED_COMPANY:
                isValid = await trigger([
                    'rc_number',
                    'tax_identification_number',
                    'certificate_of_incorporation',
                    'memorandum_of_association',
                ])
                break
            case COMPANY_TYPE.SOLE_PROPRIETORSHIP:
                isValid = await trigger(['bn_number', 'certificate_of_business_name', 'cac_bn1'])
                break

            default:
                toastHandler({ state: 'error', message: 'Please refresh your browser' })
                break
        }

        //    submit data to server

        const modifyValues = {
            legal_business_name: formValues?.legal_business_name,
            industry: formValues?.industry,
            number_of_employees: formValues?.number_of_employees,
            sub_industry: formValues?.sub_industry,
            description: formValues?.description,
            website: formValues?.website,
            country: formValues?.main?.country,
            state: formValues?.main?.state,
            city: formValues?.main?.city,
            lga: formValues?.main?.lga,
            business_email: formValues?.business_email,
            business_type: watchCompanyType,
            business_phone: formValues?.business_phone,
            rc_number: formValues?.rc_number,
            tax_identification_number: formValues?.tax_identification_number,
            cac_it_number: formValues?.cac_it_number,
            bn_number: formValues?.bn_number,
            address: {
                main: {
                    ...formValues?.main,
                },
                registered: formValues?.hasRegisteredAddress ? { ...formValues?.registered } : { ...formValues?.main },
            },
            certificate_of_business_name: formValues?.certificate_of_business_name,
            cac_bn1: formValues?.cac_bn1,
            certificate_of_incorporation: formValues?.certificate_of_incorporation,
            memorandum_of_association: formValues?.memorandum_of_association,
            cac_it_form: formValues?.cac_it_form,
            scuml_certificate: formValues?.scuml_certificate,
            proof_of_address: formValues?.proof_of_address,
            postal_code: formValues?.main?.postalCode,
            businessBvn: owners?.[0]?.data?.bvn,
            bvn: owners?.[0]?.data?.bvn,
            verification_status: 'UNVERIFIED',
            officers: watchOfficers.map(officer => ({
                address: officer.address,
                fullName: {
                    firstName: officer.fullName.firstName,
                    lastName: officer.fullName.lastName,
                },
                ...officer.data,
                percentageOwned: Number(officer.data.percentageOwned) / 100,
            })),
            complete: true,
        }

        const serverObj = Object.entries(modifyValues).reduce<Record<string, any>>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key as keyof typeof acc] = value
            }
            return acc
        }, {})

        if (isValid) {
            // console.log(serverObj)
            completeKYBFn(serverObj)
        }
    }

    return (
        <>
            <section>
                <div className='flex justify-between border-b border-gray5 pb-2 items-center'>
                    <h3 className='font-medium text-md'>Incorporation Information</h3>
                </div>
                <section className='grid grid-cols-2 2xl:grid-cols-3 gap-5 my-6'>
                    <div className='flex-1 flex flex-col gap-2  w-full md:w-auto'>
                        <label htmlFor={'company_type'} className='text-gray7 font-normal text-sm'>
                            Company type
                        </label>
                        <Controller
                            name='company_type'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <Select {...field} onValueChange={field.onChange}>
                                        <SelectTrigger className=' h-12 border-gray5 bg-base_gray_100 text-sm '>
                                            {!watchCompanyType && (
                                                <span className='text-gray1'>Select Company Type</span>
                                            )}
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent className='w-80 max-h-52 overflow-scroll '>
                                            <SelectItem value={COMPANY_TYPE.NGO}>NGO</SelectItem>
                                            <SelectItem value={COMPANY_TYPE.PRIVATE_LIMITED_COMPANY}>
                                                PRIVATE LIMITED COMPANY
                                            </SelectItem>
                                            <SelectItem value={COMPANY_TYPE.PUBLIC_LIMITED_COMPANY}>
                                                PUBLIC LIMITED COMPANY
                                            </SelectItem>
                                            <SelectItem value={COMPANY_TYPE.SOLE_PROPRIETORSHIP}>
                                                SOLE PROPRIETORSHIP
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                    {error && <ErrorText />}
                                </>
                            )}
                        />
                    </div>

                    {!!watchCompanyType && (
                        <>
                            {watchCompanyType === COMPANY_TYPE.SOLE_PROPRIETORSHIP && (
                                <Controller
                                    name='bn_number'
                                    rules={{
                                        required: true,
                                    }}
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <div className='flex-1 flex flex-col gap-2 w-full md:w-auto'>
                                            <label htmlFor='bn_number' className='text-gray7 font-normal text-sm'>
                                                BN number
                                            </label>

                                            <Input
                                                {...field}
                                                className='min-w-full h-12  placeholder:pl-2 border-gray5'
                                                type={'number'}
                                                placeholder={`Enter BN number`}
                                            />
                                            {error && <ErrorText />}
                                        </div>
                                    )}
                                />
                            )}
                            {(watchCompanyType === COMPANY_TYPE.PRIVATE_LIMITED_COMPANY ||
                                watchCompanyType === COMPANY_TYPE.PUBLIC_LIMITED_COMPANY) && (
                                <>
                                    <Controller
                                        name='rc_number'
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <div className='flex-1 flex flex-col gap-2 w-full md:w-auto'>
                                                <label htmlFor='rc_number' className='text-gray7 font-normal text-sm'>
                                                    RC number
                                                </label>

                                                <Input
                                                    {...field}
                                                    className='min-w-full h-12  placeholder:pl-2 border-gray5'
                                                    type={'number'}
                                                    placeholder={`Enter RC number`}
                                                />
                                                {error && <ErrorText />}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name='tax_identification_number'
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <div className='flex-1 flex flex-col gap-2 w-full md:w-auto'>
                                                <label
                                                    htmlFor='tax_identification_number'
                                                    className='text-gray7 font-normal text-sm'
                                                >
                                                    Tax identification number
                                                </label>
                                                <Input
                                                    {...field}
                                                    className='min-w-full h-12  placeholder:pl-2 border-gray5'
                                                    // type={'number'}
                                                    placeholder={`Enter Tax identification number`}
                                                />
                                                {error && <ErrorText />}
                                            </div>
                                        )}
                                    />
                                </>
                            )}

                            {watchCompanyType === COMPANY_TYPE.NGO && (
                                <Controller
                                    name='cac_it_number'
                                    rules={{
                                        required: true,
                                    }}
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <div className='flex-1 flex flex-col gap-2 w-full md:w-auto'>
                                            <label htmlFor='cac_it_number' className='text-gray7 font-normal text-sm'>
                                                CAC IT Number
                                            </label>
                                            <Input
                                                {...field}
                                                className='min-w-full h-12  placeholder:pl-2 border-gray5'
                                                type={'number'}
                                                placeholder={`Enter CAC IT Number`}
                                            />
                                            {error && <ErrorText />}
                                        </div>
                                    )}
                                />
                            )}
                            <Controller
                                name='registration_date'
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <div className='flex-1 flex flex-col gap-2 '>
                                        <label
                                            htmlFor={'registration_date'}
                                            className='text-gray7 font-normal text-sm '
                                        >
                                            Company registration date
                                        </label>
                                        <CustomDatePicker
                                            ref={calendarRef1}
                                            placeholderText='Select Date'
                                            onChange={field.onChange}
                                            selected={field.value as unknown as Date}
                                            value={field.value as unknown as string}
                                            dateFormatCalendar='yyyy'
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            className=' w-52'
                                            dropdownMode='select'
                                        />
                                        {error && <ErrorText />}
                                    </div>
                                )}
                            />
                        </>
                    )}
                </section>

                {!!watchCompanyType && (
                    <div className='flex flex-col gap-4'>
                        <>
                            <div className=''>
                                <h3 className='font-medium text-md'>Officers</h3>
                                {watchCompanyType !== COMPANY_TYPE.SOLE_PROPRIETORSHIP && (
                                    <p className='text-xs text-errorRed font-medium mt-1 animate-bounce'>
                                        You must add at least one Owner and one Director
                                    </p>
                                )}
                                {watchCompanyType === COMPANY_TYPE.SOLE_PROPRIETORSHIP && (
                                    <p className='text-xs text-errorRed font-medium mt-1 animate-bounce'>
                                        You must add at least one Owner
                                    </p>
                                )}
                            </div>
                            <Button
                                onClick={() => {
                                    setOpenOfficerModal(true)
                                    append(officersEmptyState)
                                }}
                                className='min-h-[32px] w-28 bg-transparent text-brand border-brand border whitespace-nowrap hover:text-white'
                            >
                                Add Officer
                            </Button>

                            <div className='flex flex-wrap gap-2.5 mt-2'>
                                {fields.map((field, idx) => (
                                    <Fragment key={field.id}>
                                        <div
                                            className='px-3 py-2 text-gray7 flex gap-4 bg-gray2/20 items-center rounded-lg text-sm font-medium'
                                            key={field.id}
                                        >
                                            <p
                                                onClick={() => {
                                                    setFieldIdx(idx)
                                                    setEditOfficerModal(true)
                                                }}
                                                className='cursor-pointer'
                                            >
                                                {field.data.email ?? field?.fullName?.firstName ?? field?.id}
                                            </p>

                                            <CgClose
                                                color='#CF491E'
                                                onClick={() => remove(idx)}
                                                className='cursor-pointer'
                                            />
                                        </div>
                                        {openOfficerModal && (
                                            <AddOfficerComplianceModal
                                                index={idx}
                                                removeItem={() => remove(idx)}
                                                closeModal={() => setOpenOfficerModal(false)}
                                            />
                                        )}
                                    </Fragment>
                                ))}
                            </div>

                            <div className='mt-9 space-y-5'>
                                <UploadKYBDocument name='proof_of_address' title='Proof of address' />
                                {watchCompanyType === COMPANY_TYPE.SOLE_PROPRIETORSHIP && (
                                    <>
                                        <UploadKYBDocument
                                            name='certificate_of_business_name'
                                            title='Certificate of Business Name'
                                        />
                                        <UploadKYBDocument name='cac_bn1' title='CAC-BN1' />
                                    </>
                                )}

                                {(watchCompanyType === COMPANY_TYPE.PRIVATE_LIMITED_COMPANY ||
                                    watchCompanyType === COMPANY_TYPE.PUBLIC_LIMITED_COMPANY) && (
                                    <>
                                        <UploadKYBDocument
                                            name='certificate_of_incorporation'
                                            title='Certificate of Incorporation'
                                        />
                                        <UploadKYBDocument
                                            name='memorandum_of_association'
                                            title='Memorandum and articles of association'
                                        />
                                    </>
                                )}
                                {watchCompanyType === COMPANY_TYPE.NGO && (
                                    <>
                                        <UploadKYBDocument name='cac_it_form' title='CAC IT form 1' />
                                        <UploadKYBDocument name='scuml_certificate' title='SCUML Certificate' />
                                    </>
                                )}
                            </div>
                        </>
                    </div>
                )}
            </section>
            <div className='flex items-center justify-end gap-6 mt-10'>
                <Button
                    variant={'outline'}
                    className='min-w-[120px]  whitespace-nowrap '
                    onClick={() => setStep(prev => prev - 1)}
                >
                    Back
                </Button>
                <Button loading={isLoading} onClick={handleSubmit} className=' whitespace-nowrap min-w-[130px]'>
                    Submit
                </Button>
            </div>

            {editOfficerModal && (
                <AddOfficerComplianceModal
                    index={fieldIdx}
                    removeItem={() => remove(fieldIdx)}
                    edit
                    closeModal={() => setEditOfficerModal(false)}
                />
            )}
        </>
    )
}
