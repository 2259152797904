import { Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { useAuthContext, useCurrencyContext } from '@/context'
import { useGetDedicatedBankAccounts } from '@/services/accounts/queries'
import { DedicatedBankAccountType } from '@/types/accounts'
import { calculateFundingFee, formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { Switch } from '@headlessui/react'
import { Percent } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { TfiAngleUp } from 'react-icons/tfi'
import { FormStateType, paymentOptions } from './utils'

type Props = {
    setFormState: React.Dispatch<React.SetStateAction<FormStateType>>
    formState: FormStateType
    sub_total: number
}

export const InvoiceSettingsForm = ({ formState, setFormState, sub_total }: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const { selectedAccount } = useAuthContext()
    const { currencies } = useCurrencyContext()
    const total = Number(sub_total) + Number(formState?.tax ?? 0)
    const partPaymentAmount = Number((Number(formState?.part_payment_percent) / 100) * (total / 100))

    const { data, isLoading, isRefetching } = useGetDedicatedBankAccounts({
        queryParams: { entity_id: selectedAccount?.org?.id as string },
    })

    const bankAccounts = useMemo(() => data ?? ([] as DedicatedBankAccountType[]), [data])

    return (
        <>
            <Accordion type='single' defaultValue='other-options' className='w-full' collapsible>
                <AccordionItem
                    value='payment-method'
                    className='w-full h-fit border border-[#DADCE0] rounded-xl p-3  shadow-sm '
                >
                    <AccordionTrigger
                        righticon={
                            <TfiAngleUp
                                className={`w-3 h-3 transition-all ${isOpen ? 'rotate-180' : ''}`}
                                fontSize={10}
                            />
                        }
                        className='w-full flex items-center justify-between'
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span className='text-[#202020] text-base  font-medium flex items-center gap-1'>
                            Payment method
                        </span>
                    </AccordionTrigger>
                    <AccordionContent className='mt-3'>
                        <RadioGroup
                            value={formState.payment_method}
                            className='flex flex-wrap gap-3  flex-row'
                            onValueChange={(value: string) => {
                                setFormState({ ...formState, payment_method: value })
                            }}
                        >
                            <>
                                {paymentOptions.map((options, i) => (
                                    <>
                                        <label
                                            htmlFor={options.value}
                                            key={i}
                                            className={`border border-[#DADCE0] rounded-xl w-full p-3 cursor-pointer ${!selectedAccount?.org.kyb_completed && i !== 1 && 'opacity-50'}`}
                                        >
                                            <div className={`flex gap-2 items-center cursor-pointer`}>
                                                <RadioGroupItem
                                                    type='button'
                                                    value={options.value}
                                                    id={options.value}
                                                    disabled={!selectedAccount?.org.kyb_completed && i !== 1}
                                                />
                                                <p className='text-sm text-[#5E5E5E]'>{options.name}</p>
                                            </div>
                                            {i === 0 && (
                                                <>
                                                    <div>
                                                        <h3 className='text-sm font-medium my-2'>
                                                            Online options via Paystack
                                                        </h3>
                                                        <ul className='list-disc list-inside text-sm text-[#5E5E5E] xl:space-y-1 grid sm:flex grid-cols-2 sm:grid-cols-4 xl:block items-center gap-6 gap-y-2'>
                                                            <li>Card</li>
                                                            <li>USSD</li>
                                                            <li>Bank transfer</li>
                                                            <li>Bank account</li>
                                                        </ul>
                                                    </div>
                                                    <div className='w-fit mt-2'>
                                                        <Accordion
                                                            type='single'
                                                            collapsible
                                                            className={`${isOpen ? 'bg-[#EFFFED]' : 'pl-0'} rounded-lg`}
                                                        >
                                                            <AccordionItem value='item-1' className='border-none'>
                                                                <AccordionTrigger
                                                                    righticon
                                                                    className='w-fit'
                                                                    onClick={() => setIsOpen(!isOpen)}
                                                                >
                                                                    <p
                                                                        className={`text-green bg-green/10  rounded-md ${!isOpen ? 'rounded-b-none' : ''} text-xs px-2 py-1.5 flex items-center  gap-3 justify-between`}
                                                                    >
                                                                        <span>Fees apply</span>
                                                                        <span>
                                                                            {isOpen ? (
                                                                                <TfiAngleUp
                                                                                    className='w-3 h-3'
                                                                                    fontSize={10}
                                                                                />
                                                                            ) : (
                                                                                <TfiAngleUp
                                                                                    className='w-3 h-3 rotate-180'
                                                                                    fontSize={10}
                                                                                />
                                                                            )}
                                                                        </span>
                                                                    </p>
                                                                </AccordionTrigger>
                                                                <AccordionContent>
                                                                    <div className='text-[#5E5E5E] text-xs font-medium p-2 bg-green/10 rounded-b-md rounded-tr-md'>
                                                                        <p className=' border-b border-[#DADCE0] pb-3'>
                                                                            A fee will be deducted from the amount the
                                                                            customer pays.
                                                                        </p>
                                                                        {Number(total) && (
                                                                            <p className='mt-2'>
                                                                                {' '}
                                                                                Fee :{' '}
                                                                                {formatAmount(
                                                                                    calculateFundingFee(total / 100) *
                                                                                        100
                                                                                )}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </AccordionContent>
                                                            </AccordionItem>
                                                        </Accordion>
                                                    </div>
                                                </>
                                            )}
                                            {i === 1 && (
                                                <div>
                                                    <h3 className='text-sm font-medium my-2'>
                                                        Online options for Naira
                                                    </h3>
                                                    <ul className='list-disc list-inside text-sm text-[#5E5E5E] space-y-1'>
                                                        <li>Cash only</li>
                                                    </ul>
                                                </div>
                                            )}
                                        </label>
                                    </>
                                ))}
                            </>
                        </RadioGroup>
                        {!selectedAccount?.org.kyb_completed && (
                            <p className='text-sm mt-3 text-gray7'>
                                Complete KYB complaince to have access to online payments
                            </p>
                        )}
                        <div className='hidden'>
                            <label
                                htmlFor='part_payment'
                                className='cursor-pointer flex items-center text-sm gap-3 mt-3 text-gray7'
                            >
                                <input
                                    type='checkbox'
                                    name='part_payment'
                                    id='part_payment'
                                    checked={formState?.part_payment}
                                    onChange={e =>
                                        setFormState(prev => ({
                                            ...prev,
                                            part_payment: e.target.checked,
                                        }))
                                    }
                                />
                                Accept part payment
                            </label>
                        </div>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem
                    value='payout_bank_account'
                    className='w-full h-fit border border-[#DADCE0] rounded-xl p-3  shadow-sm mt-4'
                >
                    <AccordionTrigger
                        righticon={
                            <TfiAngleUp
                                className={`w-3 h-3 transition-all ${isOpen ? 'rotate-180' : ''}`}
                                fontSize={10}
                            />
                        }
                        className='w-full flex items-center justify-between'
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span className='text-[#202020] text-base  font-medium flex items-center gap-1'>
                            Receive Payment in this account{' '}
                        </span>
                    </AccordionTrigger>
                    <AccordionContent className={`mt-3 ${isLoading || isRefetching ? 'opacity-60' : ''}`}>
                        <RadioGroup
                            value={formState.account}
                            className='flex flex-wrap gap-3 flex-row max-h-[267px] overflow-y-scroll'
                            onValueChange={(value: string) => {
                                setFormState({ ...formState, account: value })
                            }}
                        >
                            {bankAccounts?.map((account, i) => (
                                <label htmlFor={account?._id} key={i} className={` w-full  cursor-pointer `}>
                                    <div className={`flex gap-2 items-center cursor-pointer`}>
                                        <RadioGroupItem type='button' value={account?._id} id={account?._id} />
                                        <p className='text-sm text-[#5E5E5E]'>
                                            {account?.nickname ?? account?.account_name} (
                                            {formatAmount(account?.balance)})
                                        </p>
                                    </div>
                                </label>
                            ))}
                        </RadioGroup>

                        <div className='hidden'>
                            <label
                                htmlFor='part_payment'
                                className='cursor-pointer flex items-center text-sm gap-3 mt-3 text-gray7'
                            >
                                <input
                                    type='checkbox'
                                    name='part_payment'
                                    id='part_payment'
                                    checked={formState?.part_payment}
                                    onChange={e =>
                                        setFormState(prev => ({
                                            ...prev,
                                            part_payment: e.target.checked,
                                        }))
                                    }
                                />
                                Accept part payment
                            </label>
                        </div>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem
                    value='other-options'
                    className='w-full h-fit border border-[#DADCE0] rounded-xl p-3  shadow-sm mt-4'
                >
                    <AccordionTrigger
                        righticon={
                            <TfiAngleUp
                                className={`w-3 h-3 transition-all ${isOpen ? 'rotate-180' : ''}`}
                                fontSize={10}
                            />
                        }
                        className='w-full flex items-center justify-between'
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span className='text-[#202020] text-base  font-medium flex items-center gap-1'>Settings</span>
                    </AccordionTrigger>
                    <AccordionContent className='mt-3'>
                        <div className='border border-gray4 rounded-md p-3'>
                            <fieldset className='mb-4 flex flex-col  flex-1 '>
                                <label className='mb-1.5  text-sm opacity-80' htmlFor='currency'>
                                    Currency
                                </label>
                                <Select
                                    value={formState.currency}
                                    onValueChange={value => {
                                        setFormState({ ...formState, currency: value })
                                    }}
                                    name='currency'
                                    disabled={formState?.item?.length > 0}
                                >
                                    <SelectTrigger className='font-medium text-xs h-[3rem] text-[#5E5E5E]  border-gray4/60 '>
                                        <SelectValue className='' placeholder={'Select currency'} />
                                    </SelectTrigger>
                                    <SelectContent className=''>
                                        {currencies?.map(currency => (
                                            <SelectItem className='text-xs' value={currency?.id}>
                                                <span>{currency?.symbol}</span> - {currency?.name}(
                                                {currency?.currency_code})
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </fieldset>
                            <div className='flex justify-between items-center'>
                                <h2 className='font-medium text-sm opacity-70'>Allow partial payment</h2>
                                <div>
                                    <div className='flex justify-between text-xs font-medium text-gray7 gap-4 mb-1'>
                                        <span>No</span>
                                        <span>Yes</span>
                                    </div>
                                    <Switch
                                        type='button'
                                        onChange={e =>
                                            setFormState(prev => ({
                                                ...prev,
                                                part_payment: e,
                                            }))
                                        }
                                        checked={formState?.part_payment}
                                        className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                                            formState?.part_payment ? 'bg-brand' : 'bg-[#EFEFF9]'
                                        }`}
                                    >
                                        <span
                                            className={`${
                                                !formState?.part_payment
                                                    ? 'translate-x-1 bg-brand'
                                                    : 'translate-x-6 bg-white'
                                            } inline-block h-4 w-4 transform rounded-full  transition`}
                                        />
                                    </Switch>
                                </div>
                            </div>

                            {formState.part_payment && (
                                <div className='mt-3 hidden'>
                                    <h2 className='font-medium text-sm opacity-70 mb-1.5'>Minimum part payment</h2>
                                    <div className='flex gap-3 items-center'>
                                        <Input
                                            rightIcon={<Percent />}
                                            className='h-9 border-gray8'
                                            bodyClassName='!min-w-[6.5rem] !max-w-[6.5rem]'
                                            min={0}
                                            value={formatAmount(formState?.part_payment_percent).replaceAll('₦', '')}
                                            onChange={e => {
                                                const value_entered = formatAmountWithoutSymbols(e.target.value)
                                                if (Number(value_entered) > 10000) return
                                                setFormState(prevState => ({
                                                    ...prevState,
                                                    part_payment_percent: Number(value_entered),
                                                }))
                                            }}
                                        />
                                        {partPaymentAmount > 0 && (
                                            <p className='text-brand text-xs font-medium'>
                                                Customer to pay not less than {formatAmount(partPaymentAmount)}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>

            <div className={`flex flex-col gap-2 item-center text-[#5E5E5E] mt-4`}>
                <label htmlFor='footnote' className='text-sm font-medium cursor-pointer'>
                    Add footnote to invoice PDF
                    <Input
                        className={`min-w-full h-[3rem] w-full  lg:w-[20rem] placeholder:pl-2 border-[#DADCE0] mt-2`}
                        type='text'
                        placeholder={`Enter footnote text`}
                        name='footnote'
                        id='footnote'
                        value={formState?.footer}
                        onChange={e => {
                            setFormState(prev => ({
                                ...prev,
                                footer: e.target.value,
                            }))
                        }}
                    />
                </label>
            </div>
        </>
    )
}
