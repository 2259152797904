import emptyExpense from '@/assets/empty-state-expense.svg'
import { ReactComponent as MoneyIcon } from '@/assets/sidebar-expense.svg'
import { OverAPeriodIcon, RecentExpensesIcon } from '@/assets/assets.export'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import ProgressBar from '@/components/ProgressBar'
import { CustomBarTooltip, CustomPieTooltip } from '@/components/Tooltips/CustomTooltip'
import { useAuthContext } from '@/context'
import {
    useGetExpenseByCategory,
    useGetExpenseByMonth,
    useGetExpenseSummary,
    useGetTopSpenders,
} from '@/services/expenses/queries'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { getYear, startOfMonth, subMonths, subYears } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Pie,
    PieChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { ADMIN_EXPENSE_PERMISSIONS } from '@/utils/permisson-helper'

export type CustomTooltipProps = {
    active?: any
    payload?: any
    label?: any
}

const ExpenseAnalytics = () => {
    const { selectedAccount } = useAuthContext()
    const categoryDate = new Date()

    const orgExpensePermissions = ADMIN_EXPENSE_PERMISSIONS.every(perm =>
        selectedAccount?.role?.permissions?.includes(perm)
    )

    const dateThreeMonthsAgo = startOfMonth(subMonths(categoryDate, 3))

    const thisYear = new Date().getFullYear()
    const [expenseYear, setExpenseYear] = useState(thisYear + '')
    const [summaryYear, setSummaryYear] = useState(thisYear + '')
    const [topSpenndersYear, setTopSpenndersYear] = useState(`${getYear(new Date())}`)

    const canSeeOrgAnalytics = orgExpensePermissions && window.location.href.includes('company')

    const [expenseCategoryDate, setExpenseCategoryDate] = useState(`${startOfMonth(subMonths(new Date(), 0))}`)
    const COLORS = [
        '#57E1FF',
        '#FF9152',
        '#5C9BFF',
        '#F4DD09',
        '#93F',
        '#AD381E',
        '#FC52FF',
        '#FFD6C9',
        '#A2AD1E',
        '#45CFB6',
        '#DFFFC9',
        '#FFC9D7',
        '#D6C9FF',
        'purple',
        'black',
        'red',
    ]

    const {
        data,
        isLoading,
        refetch: refetchExpenseSummary,
        isRefetching: refetchingSummary,
    } = useGetExpenseSummary({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            year: summaryYear,
            user_id: !canSeeOrgAnalytics ? `${selectedAccount?.id as string}` : '',
        },
    })

    const {
        data: expenseCategoryData,
        isLoading: isCategoryExpenseLoading,
        refetch: refetchExpenseByCategory,
        isRefetching: refetchingCategory,
    } = useGetExpenseByCategory({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            start_date: expenseCategoryDate,
            end_date: new Date().toDateString(),
        },
    })
    const {
        data: expenseByMonth,
        isLoading: isExpenseByMonthLoading,
        refetch: refetchExpenseByMonth,
        isRefetching: refetchingExpenseByMonth,
    } = useGetExpenseByMonth({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            year: expenseYear,
        },
    })
    const {
        data: topSpenders,
        refetch: refetchTopSpenders,
        isRefetching: isTopSpendersRefetching,
    } = useGetTopSpenders({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            year: topSpenndersYear,
        },
        enabled: canSeeOrgAnalytics,
    })

    const expenseSummary = useMemo(() => data, [data])
    const expenseByCategory = useMemo(() => expenseCategoryData, [expenseCategoryData])
    const expenseByMonthData = useMemo(() => expenseByMonth, [expenseByMonth])
    const topSpendersData = useMemo(() => topSpenders, [topSpenders])
    const pieChartData: { category: string; totalAmount: number }[] = []
    if (expenseByCategory) {
        for (const [, value] of Object.entries(expenseByCategory)) {
            pieChartData.push({
                category: value.category,
                totalAmount: Number(value.totalAmount),
            })
        }
    }
    const sameTotalAmountSpent = pieChartData?.filter(data => data?.totalAmount === pieChartData?.[0]?.totalAmount)

    const totalAmount = expenseByMonthData?.reduce(
        (accumulator, currentValue) => accumulator + parseFloat(currentValue.total),
        0
    )

    useEffect(() => {
        refetchExpenseSummary()
    }, [refetchExpenseSummary, summaryYear, canSeeOrgAnalytics])
    useEffect(() => {
        refetchExpenseByCategory()
    }, [expenseCategoryDate, refetchExpenseByCategory, canSeeOrgAnalytics])
    useEffect(() => {
        refetchExpenseByMonth()
    }, [expenseYear, refetchExpenseByMonth, canSeeOrgAnalytics])
    useEffect(() => {
        if (canSeeOrgAnalytics) {
            refetchTopSpenders()
        }
    }, [topSpenndersYear, refetchTopSpenders])

    if (isLoading || isCategoryExpenseLoading || isExpenseByMonthLoading) return <ProgressBar />

    const percentageIcon = (Number(expenseSummary?.totalApprovedAmount) / Number(100000000)) * 100 //calculations here are in kobo
    function calculateSpenderPercentage(spenderAmount: number): number {
        const totalAmount = topSpendersData?.reduce((acc, spender) => acc + spender.amount, 0)

        const percentage = (spenderAmount / totalAmount!) * 100
        return parseFloat(percentage.toFixed(1))
    }

    return (
        <div className='w-full py-6 block'>
            <section className='p-6 rounded-[20px] mb-6 bg-white'>
                <div className='flex justify-between items-start mb-6'>
                    <span className='font-semibold block text-[20px]'>Expense insights</span>

                    <div>
                        <Select name='expenseYear' onValueChange={setSummaryYear} value={summaryYear}>
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[8rem]'>
                                <SelectItem value={`${thisYear}`}>This year</SelectItem>
                                <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className={`grid gird-cols-1 md:grid-cols-8 gap-[20px] ${refetchingSummary ? 'opacity-30' : ''}`}>
                    <div className='col-span-3 py-2 flex justify-between flex-col '>
                        <div className='flex gap-6 mb-4 md:mb-0'>
                            <div className='bg-[#EFEFF9] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <MoneyIcon className={`fill-brand stroke-[#fff] scale-125`} />
                            </div>
                            <div className=''>
                                <span className='text-[#5E5E5E] text-lg font-medium block '>Total expense amount</span>
                                <span className='text-3xl lg:text-4xl font-bold block'>
                                    {formatAmount(expenseSummary?.totalApprovedAmount)}
                                    {/* ₦6,000,977,000.20 */}
                                </span>
                            </div>
                        </div>
                        <div className='flex justify-between items-center'>
                            <p className='text-[#5e5e5e] text-sm font-semibold'>
                                Spent
                                <span className='text-[#CF491E]  '> {percentageIcon.toFixed(2)}% </span>
                                more than previous year ({summaryYear})
                            </p>
                        </div>
                    </div>

                    <div className='col-span-5 grid grid-cols-2 md:grid-cols-3 gap-[10px]'>
                        <div className='flex gap-2 md:gap-6'>
                            <div className='bg-[#EFF7F9] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <p className='w-[50px] flex items-center justify-center'>
                                    <MoneyIcon className={`fill-[#45B0DE] stroke-[#fff] scale-125`} />
                                </p>
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Total expense count</p>
                                <p className='text-xl lg:text-2xl font-medium '>{expenseSummary?.totalExpenses}</p>
                            </div>
                        </div>
                        <div className='flex gap-2 md:gap-6'>
                            <div className='bg-[#FFFEEC] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <MoneyIcon className={`fill-[#DEB145] stroke-[#fff] scale-125`} />
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Pending</p>
                                <p className='text-xl lg:text-2xl font-medium '>{expenseSummary?.pending}</p>
                            </div>
                        </div>
                        <div className='flex gap-2 md:gap-6'>
                            <div className='bg-[#FFEFE7] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <p className='w-[50px] flex items-center justify-center'>
                                    <MoneyIcon className={`fill-[#FF9152] stroke-[#fff] scale-125`} />
                                </p>
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Partially Approved</p>
                                <p className='text-xl lg:text-2xl font-medium '>
                                    {expenseSummary?.partiallyApproved ?? 0}
                                </p>
                            </div>
                        </div>
                        <div className='flex gap-2 md:gap-6'>
                            <div className='bg-[#EFFFED] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <MoneyIcon className={`fill-brightGreen stroke-[#fff] scale-125`} />
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Approved</p>
                                <p className='text-xl lg:text-2xl font-medium '>{expenseSummary?.approved ?? 0}</p>
                            </div>
                        </div>
                        <div className='flex gap-2 md:gap-6'>
                            <div className='bg-lightBlue rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <p className='w-[50px] flex items-center justify-center'>
                                    <MoneyIcon className={`fill-skyBlue stroke-[#fff] scale-125`} />
                                </p>
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Reimbursed</p>
                                <p className='text-xl lg:text-2xl font-medium '>{expenseSummary?.reimbursed ?? 0}</p>
                            </div>
                        </div>
                        <div className='flex gap-2 md:gap-6'>
                            <div className='bg-[#FFEDED] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <MoneyIcon className={`fill-errorRed stroke-[#fff] scale-125`} />
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Declined</p>
                                <p className='text-xl lg:text-2xl font-medium '>{expenseSummary?.denied ?? 0}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bg-white p-2 sm:p-4 pt-6 rounded-lg mb-6'>
                <div className='flex items-center justify-between mb-6'>
                    <span className='font-semibold'>Expense overview by time period</span>
                    <div>
                        <Select name='expenseYear' onValueChange={setExpenseYear} value={expenseYear}>
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[8rem]'>
                                <SelectItem value={`${thisYear}`}>This year</SelectItem>
                                <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                {/*==== Bar chart ====*/}
                {totalAmount! > 0 ? (
                    <div className={`bg-white sm:p-4 rounded-md ${refetchingExpenseByMonth ? 'opacity-30' : ''}`}>
                        <ResponsiveContainer height={350} width={'100%'} className={'mx-auto'}>
                            <BarChart data={expenseByMonthData} margin={{ left: 60 }}>
                                <CartesianGrid stroke='#DADCE0' vertical={false} strokeDasharray='5 5' fill='white' />
                                <XAxis
                                    dataKey='name'
                                    stroke='#888888'
                                    fontSize={12}
                                    tickLine={false}
                                    axisLine={false}
                                    // widths={90}
                                    // hide={}
                                />
                                <YAxis
                                    dataKey='total'
                                    stroke='#888888'
                                    fontSize={12}
                                    tickLine={false}
                                    axisLine={false}
                                    tickFormatter={value => formatAmount(value)}
                                    hide={false}
                                    label={{
                                        value: 'Expenses in Naira',
                                        angle: -90,
                                        position: 'insideLeft',
                                        offset: -50,
                                    }}
                                    offset={10}
                                    // domain={[0, 'dataMax + 1']}
                                    type='number'
                                    // domain={[0, 'dataMax + 1']}
                                />
                                <Bar dataKey='total' fill='#454ADE' radius={[4, 4, 0, 0]} barSize={50} />
                                <Tooltip
                                    filterNull={true}
                                    cursor={false}
                                    wrapperStyle={{ borderRadius: '15px', zIndex: '10' }}
                                    content={<CustomBarTooltip />}
                                />
                                <ReferenceLine y={0} stroke='#DADCE0' type='linear' />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                ) : (
                    <div
                        className={`h-[400px] flex flex-col text-center justify-center items-center ${
                            refetchingExpenseByMonth ? 'opacity-30' : ''
                        }`}
                    >
                        <span className='text-2xl lg:text-3xl font-bold text-[#5E5E5E]'>
                            No Expense Details Available
                        </span>
                        <span className='text-[#202020] text-sm lg:text-[18px] w-full lg:w-[60%] mx-auto mt-3 font-medium'>
                            You haven't recorded any expense for this time period. A summary of expense habits and
                            expenses over time will be shown here when expenses are created
                        </span>
                        <img src={emptyExpense} alt='widget' className='h-[150px] lg:h-[270px]' />
                    </div>
                )}
            </section>

            <section className='p-5 rounded-[20px] bg-white mb-6'>
                <div className='flex items-center justify-between mb-6'>
                    <span className='font-semibold'>Expense by category</span>

                    <div>
                        <Select
                            name='expenseCategoryDate'
                            onValueChange={setExpenseCategoryDate}
                            value={expenseCategoryDate}
                        >
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[9rem]'>
                                <SelectItem value={`${startOfMonth(subMonths(new Date(), 0))}`}>This month</SelectItem>
                                <SelectItem value={`${startOfMonth(subMonths(new Date(), 1))}`}>Last month</SelectItem>
                                <SelectItem value={`${dateThreeMonthsAgo}`}>Last 3 months</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-5 md:gap-x-[20px] gap-y-[20px] min-h-[100px]'>
                    <div
                        className={`relative border-b md:border-r border-[#DADCE0] w-full col-span-2 p-4 ${
                            refetchingCategory ? 'opacity-30' : ''
                        } ${expenseByCategory && expenseByCategory?.length > 0 ? '' : 'hidden'}   `}
                        // style={{ height: `${min_height}` }}
                    >
                        <ResponsiveContainer height={'50%'} className={'min-h-[22rem] relative'}>
                            <PieChart width={800} height={700}>
                                <Pie
                                    data={pieChartData}
                                    cy={170}
                                    innerRadius={100}
                                    outerRadius={147}
                                    fill='#8884d8'
                                    // paddingAngle={5}
                                    dataKey='totalAmount'
                                    nameKey='category'
                                    height={300}
                                >
                                    {pieChartData.map((_, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                {/* <Legend
                                    layout='vertical'
                                    formatter={renderColorfulLegendText}
                                    iconType='square'
                                    wrapperStyle={{
                                        position: 'absolute',
                                        display: 'block',
                                        left: '15%',
                                        top: '350px',
                                    }}
                                /> */}
                                <Tooltip offset={1} content={<CustomPieTooltip />} wrapperStyle={{ zIndex: '10' }} />
                            </PieChart>
                        </ResponsiveContainer>
                        {pieChartData.length > 0 && (
                            <h1 className='absolute top-[200px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center'>
                                You spent the most on
                                <br />
                                {sameTotalAmountSpent.length > 1 ? (
                                    sameTotalAmountSpent?.map((data, idx) => (
                                        <span className='font-medium text-xs block max-w-[200px]'>
                                            {data?.category}
                                            {idx === sameTotalAmountSpent?.length - 2 ? ' & ' : ''}
                                        </span>
                                    ))
                                ) : (
                                    <span className='text-sm font-semibold max-w-[200px] block'>
                                        {pieChartData[0].category}
                                    </span>
                                )}
                            </h1>
                        )}
                        <div className='grid grid-cols-3 gap-6 mt-9'>
                            {pieChartData.map((data, i) => (
                                <div className='w-full'>
                                    <span
                                        className=' h-1 block mr-2 rounded'
                                        style={{
                                            backgroundColor: `${COLORS[i]}`,
                                        }}
                                    >
                                        &nbsp;
                                    </span>
                                    <h2
                                        className='text-sm lg:text-base font-medium mt-1'
                                        style={{ overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto' }}
                                    >
                                        {data.category}
                                    </h2>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div
                        className={` rounded-lg overflow-hidden ${expenseByCategory && expenseByCategory?.length > 0 ? 'col-span-3' : 'col-span-5'} ${
                            refetchingCategory ? 'opacity-30' : ''
                        } flex flex-col gap-4 mt-4`}
                    >
                        {expenseByCategory && expenseByCategory?.length > 0 ? (
                            expenseByCategory.map(category => (
                                <>
                                    <div className='border border-[#DADCE0] rounded-lg py-4 px-5'>
                                        <div className='text-sm lg:text-base font-semibold flex justify-between items-center'>
                                            <p className='w-1/2'>{category.category}</p>
                                            <div className='flex gap-4 md:gap-12'>
                                                <p className='text-right'>{formatAmount(category.totalAmount)}</p>
                                                <p className='text-xs lg:text-sm text-brand'>{category.percentage}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))
                        ) : (
                            <>
                                <div className='flex items-center justify-center flex-col text-gray7 h-full'>
                                    <RecentExpensesIcon />
                                    <h2 className='text-gray7 font-medium mt-5'>No expenses</h2>
                                    <p className='text-sm'>No expense has been submitted yet</p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>

            {canSeeOrgAnalytics && (
                <section className='flex justify-between my-8 lg:gap-9'>
                    <section className='bg-white rounded-xl p-4 w-full lg:w-1/2 flex flex-col gap-4 h-full min-h-[450px]'>
                        <div className='flex items-center justify-between rounded text-dark mb-4'>
                            <div className=''>
                                <span className=' font-semibold text-base'>Top spenders - Users</span>
                                <p className='text-sm text-gray7 mt-1 w-[210px] md:w-auto'>
                                    See the top 5 user spenders in your organization
                                </p>
                            </div>

                            <div>
                                <Select
                                    onValueChange={dateYear => setTopSpenndersYear(dateYear)}
                                    value={topSpenndersYear}
                                >
                                    <SelectTrigger className='w-full'>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-[8rem]'>
                                        <SelectItem value={`${getYear(new Date())}`}>This year</SelectItem>
                                        <SelectItem value={`${getYear(subYears(new Date(), 1))}`}>Last year</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        <div className={`${isTopSpendersRefetching ? 'opacity-30' : ''} flex flex-col gap-4`}>
                            {topSpendersData && topSpendersData?.length > 0 ? (
                                topSpendersData
                                    ?.filter((_, i) => i < 5)
                                    .map(spender => (
                                        <>
                                            <div className='border border-[#DADCE0] rounded-lg py-4 px-5'>
                                                <div className='text-sm lg:text-base font-semibold flex justify-between items-center'>
                                                    <p className='w-1/2 flex gap-3 items-center'>
                                                        {spender.user.profile_photo ? (
                                                            <img
                                                                src={spender.user.profile_photo}
                                                                alt=''
                                                                className='w-[30px] h-[30px] rounded-full '
                                                            />
                                                        ) : (
                                                            <p className='w-[30px] h-[30px] bg-[#D9D9D9] text-[#838383] flex justify-center items-center rounded-full tracking-wider'>
                                                                {capitalizeText(spender.user.first_name)?.[0]}
                                                                {capitalizeText(spender.user.last_name)?.[0]}
                                                            </p>
                                                        )}
                                                        {spender.user.first_name} {spender.user.last_name}{' '}
                                                    </p>
                                                    <div className='flex gap-3 md:gap-10'>
                                                        <p className='text-right'>{formatAmount(spender.amount)}</p>
                                                        <p className='text-xs lg:text-sm text-brand w-[50px] text-right'>
                                                            {calculateSpenderPercentage(spender.amount)}%
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))
                            ) : (
                                <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                                    <OverAPeriodIcon />
                                    <p className='text-sm mt-6'>Nothing to see here yet</p>
                                </div>
                            )}
                        </div>
                    </section>
                    <section></section>
                </section>
            )}
        </div>
    )
}

export default ExpenseAnalytics
