import { useEffect, useMemo, useState } from 'react'
import { motion } from 'framer-motion'
import { useGetAllVaults, useGetFolderItems } from '@/services/vault/queries'
import ProgressBar from '@/components/ProgressBar'
import { VaultItem } from '@/services/vault/types'
import { useSearchParams } from 'react-router-dom'
// import { FileLists } from '../Files/FileLists'
// import { FolderLists } from '../Folders/FolderLists'
import { SingleFolderFullView } from './SingleFolderFullView'
import { LayoutGrid, StretchHorizontal } from 'lucide-react'
import { FolderContainer } from '../Folders/FolderContainer'
import { FileContainer } from '../Files/FileContainer'
import { FileEmptyState } from '../FileEmptyState'
import VaultListTable from '../VaultListTable'
import { TrashView } from './TrashView'

export const ItemsView = () => {
    const [listStyle, setListStyle] = useState(false)

    const { data: vaults, isLoading: isLoadingVaults } = useGetAllVaults({})
    const vaultsData = useMemo(() => vaults ?? [], [vaults])
    const singleVault = vaultsData?.[0]
    const [searchParams] = useSearchParams()

    const {
        data: vaultItems,
        isLoading: isLoadingVaultItems,
        refetch,
        isRefetching,
    } = useGetFolderItems({
        queryParams: {
            vault: singleVault?.id,
            parent: searchParams.get('folder') ?? singleVault?.id,
        },
        enabled: !!vaults,
    })

    const folders = vaultItems?.filter(item => item.type === 'folder')
    const files = vaultItems?.filter(item => item.type === 'file')

    useEffect(() => {
        refetch()
    }, [searchParams])

    if (isLoadingVaults || isLoadingVaultItems || isRefetching)
        return (
            <div className='w-full overflow-hidden flex items-center justify-center max-h-[600px]'>
                <ProgressBar />
            </div>
        )
    return (
        <>
            {!searchParams.get('folder') ? (
                <>
                    <div className='flex items-center gap-4 justify-between'>
                        <p className='font-medium'>{searchParams.get('path') ? 'Trash' : 'Recent'}</p>
                        {vaultItems?.length !== 0 && (
                            <>
                                <div className='flex gap-4 p-1 w-[11rem] rounded-lg border border-gray8'>
                                    <div
                                        className={`${'bg-#F9F9F9 text-brand'}bg-[#EFEFF9] px-2 py-1 rounded cursor-pointer relative`}
                                        onClick={() => {
                                            setListStyle(false)
                                        }}
                                    >
                                        {!listStyle && (
                                            <motion.span
                                                layoutId='expense_timeline__animation'
                                                className='bg-[#EFEFF9] rounded-md z-0 text-brand inset-0 absolute inline-block w-full h-full '
                                            />
                                        )}
                                        <motion.span
                                            layout='position'
                                            className={`z-20 sticky flex items-center gap-2 font-semibold ${!listStyle ? 'text-brand' : 'text-gray7'}`}
                                        >
                                            <LayoutGrid size={17} />
                                            Grid
                                        </motion.span>
                                    </div>
                                    <div
                                        className={`${'bg-#F9F9F9 text-[#5E5E5E]'}bg-[#EFEFF9] px-2 py-1 rounded cursor-pointer relative w-full`}
                                        onClick={() => {
                                            setListStyle(true)
                                        }}
                                    >
                                        {listStyle && (
                                            <motion.span
                                                layoutId='expense_timeline__animation'
                                                className='bg-[#EFEFF9] rounded-md inset-0 absolute inline-block w-full h-full '
                                            />
                                        )}
                                        <motion.span
                                            layout='position'
                                            className={`${listStyle ? 'text-brand' : 'text-gray7'} inset-0 absolute w-full h-full px-2 py-1 font-semibold`}
                                        >
                                            <p className='flex items-center gap-2'>
                                                <StretchHorizontal size={17} /> List
                                            </p>
                                        </motion.span>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className='mt-4'>
                        {searchParams.size === 0 ? (
                            <>
                                <div className='grid grid-cols-1 lg:grid-cols-4 gap-4'>
                                    {!listStyle &&
                                        (vaultItems?.length !== 0 ? (
                                            <>
                                                {vaultItems
                                                    ?.filter(item => item.type === 'folder')
                                                    ?.map(folder => (
                                                        <FolderContainer key={folder?.id} folder={folder} />
                                                    ))}
                                                {vaultItems
                                                    ?.filter(item => item.type === 'file')
                                                    ?.map(file => <FileContainer key={file.id} file={file} />)}
                                            </>
                                        ) : (
                                            <FileEmptyState type='file' />
                                        ))}
                                </div>
                                {listStyle && (
                                    <div className='w-full overflow-x-scroll'>
                                        <VaultListTable
                                            data={[...(folders as VaultItem[]), ...(files as VaultItem[])]}
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <TrashView listStyle={listStyle} items={[] as VaultItem[]} />
                            </>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <SingleFolderFullView items={vaultItems as VaultItem[]} />
                </>
            )}
        </>
    )
}
