export const formatAmount = (amount?: number | string | null) => {
    if (!amount) amount = 0
    const formatted = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
    }).format(+amount / 100)

    return formatted.replace('NGN', '₦')
}

export const formatAmountNoSymbol = (amount?: number | string | null) => {
    if (!amount) amount = 0
    const formatted = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
    }).format(+amount / 100)

    return formatted.replaceAll('NGN', '').replaceAll('₦', '')
}

export const formatAmountWhenTyped = (value: string) => {
    let amount = ''
    if (!value) return ''
    amount = value
    amount = amount.replace(/\D/g, '')
    amount = amount.replace(/(\d)(\d{2})$/, '$1. $2')
    amount = amount.replace(/(?=(\d{3})+(\D))\B/g, ',')
    return amount
}

export const formatAmountWithoutSymbols = (amount?: number | string | null) => {
    if (!amount) return '0'
    let value_entered = amount
        ?.toString()
        .replaceAll(' ', '')
        ?.replaceAll(',', '')
        ?.replaceAll('.', '')
        .replaceAll('₦', '')
    value_entered = value_entered?.replaceAll(/\D/g, '')
    return value_entered
}

export const formatAmountWithSymbolOfChoice = (amount?: number | string | null, symbols?: string[]) => {
    if (!amount) return '0'
    symbols?.map(symbol => {
        amount = amount?.toString()?.replaceAll(symbol, '')
    })
    return amount.toString()
}

export const calculateFundingFee = (amount: string | number): number => {
    const fundingAmount = typeof amount === 'string' ? parseFloat(amount) : amount

    if (isNaN(fundingAmount)) {
        throw new Error('Invalid amount. Please provide a valid numeric amount.')
    }

    let fee = 0.015 * fundingAmount
    if (fundingAmount > 2500) {
        fee += 100
    }
    fee = Math.min(fee, 2000)

    return fee
}

export const calculateTransferFee = (amountString: string): number => {
    const amount = parseFloat(amountString) / 100
    let fee: number

    if (amount <= 5000) {
        fee = 10
    } else if (amount <= 50000) {
        fee = 25
    } else {
        fee = 50
    }

    return fee
    // return 50
}

export const nairaToKobo = (amount: string | number): number => {
    const naira = typeof amount === 'string' ? parseFloat(amount) : amount
    return naira * 100
}

export const koboToNaira = (amount: string | number): number => {
    const kobo = typeof amount === 'string' ? parseFloat(amount) : amount
    return kobo / 100
}
