import { FolderIcon, Trash2 } from '@/assets/assets.export'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion/index2'
import { useVaultContext } from '@/context'
import { VaultItem } from '@/services/vault/types'
import { ChevronDown, ChevronRight } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

type NavPops = {
    folders: VaultItem[]
}
export const DocumentsNav = ({ folders }: NavPops) => {
    const nav = [
        {
            heading: 'Folder',
            subNavs: folders,
        },
    ]
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    return (
        <>
            {nav.map(head => (
                <SingleAccordion nav={head} />
            ))}
            <p
                className={`lg:border-t border-gray8 lg:pt-3 lg:mb-8 cursor-pointer lg:-mt-2 flex items-center gap-1 lg:gap-2 text-sm lg:text-base pr-3 ${searchParams.get('path') && 'text-brand font-semibold'}`}
                onClick={() => navigate('?path=trash')}
            >
                <Trash2 className={`lg:scale-[1.15] ${searchParams.get('path') && '!stroke-brand'} `} /> Trash
            </p>
        </>
    )
}

const SingleAccordion = ({ nav }: { nav: any }) => {
    const [open, setOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const itemId = searchParams.get('folder')
    const parentId = searchParams.get('parent')
    const { setSelectedFolder } = useVaultContext()
    const handleResize = () => {
        if (window.innerWidth < 1024) {
            setOpen(false)
            setIsMobile(true)
        } else {
            setIsMobile(false)
            setOpen(true)
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <>
            <Accordion type='single' collapsible defaultValue={open ? nav.heading : ''} className=''>
                <AccordionItem value={nav.heading} className='border-none relative'>
                    <AccordionTrigger
                        className='lg:border-t border-gray8 lg:pt-2 lg:pb-1'
                        onClick={() => {
                            setOpen(prev => !prev)
                        }}
                    >
                        <span className='w-full flex items-center justify-between lg:uppercase'>
                            {nav.heading}{' '}
                            <ChevronDown className={`w-4 h-4 transition-all ${open ? '-rotate-90' : ''}`} />
                        </span>
                    </AccordionTrigger>
                    {isMobile && (
                        <AccordionContent
                            className={`absolute left-[50%] z-[100] -translate-x-[50%] ${nav.heading === 'Folder' ? 'w-[188px]' : 'w-[120px]'}`}
                        >
                            <div className='flex gap-2 flex-col mt-3  bg-white border border-gray8 rounded-xl  p-3 top-8 '>
                                {nav?.subNavs?.map((sub: any) => {
                                    const active = sub.id === itemId || sub?.id === parentId
                                    if (nav?.heading === 'Folder') {
                                        return (
                                            <div
                                                className={`flex items-center justify-between w-full py-1 cursor-pointer ${active ? 'text-brand' : 'text-gray7'} `}
                                                onClick={() => {
                                                    if (sub?.parent === sub?.vault) {
                                                        setSelectedFolder(sub)
                                                    }
                                                    navigate(`?folder=${sub?.id}`)
                                                    setOpen(false)
                                                }}
                                            >
                                                <div className='flex items-center gap-2'>
                                                    <FolderIcon
                                                        color={`${active ? '#454ade' : '#5e5e5e'}`}
                                                        className='accent-brand'
                                                    />
                                                    <p className=' font-medium text-sm'>{sub?.name}</p>
                                                </div>
                                                <ChevronRight className='w-4 h-4' />
                                            </div>
                                        )
                                    } else
                                        return (
                                            <div className='flex items-center justify-between w-full py-1'>
                                                <div className='flex items-center gap-2'>
                                                    <p className='text-gray7 font-medium text-sm'>{sub}</p>
                                                </div>
                                            </div>
                                        )
                                })}
                            </div>
                        </AccordionContent>
                    )}
                    {!isMobile && (
                        <AccordionContent>
                            <div className='flex gap-2 flex-col mt-3'>
                                {nav.subNavs.map((sub: any) => {
                                    const active = sub.id === itemId || sub.id === parentId
                                    if (nav.heading === 'Folder') {
                                        return (
                                            <div
                                                className={`flex items-center justify-between w-full py-1 cursor-pointer ${active ? 'text-brand' : 'text-gray7'} `}
                                                onClick={() => {
                                                    if (sub?.parent === sub?.vault) {
                                                        setSelectedFolder(sub)
                                                    }
                                                    navigate(`?folder=${sub.id}`)
                                                }}
                                            >
                                                <div className='flex items-center gap-2'>
                                                    <FolderIcon
                                                        color={`${active ? '#454ade' : '#5e5e5e'}`}
                                                        className='accent-brand'
                                                    />
                                                    <p className=' font-medium text-sm'>{sub.name}</p>
                                                </div>
                                                <ChevronRight className='w-4 h-4' />
                                            </div>
                                        )
                                    } else
                                        return (
                                            <div className='flex items-center justify-between w-full py-1'>
                                                <div className='flex items-center gap-2'>
                                                    <p className='text-gray7 font-medium text-sm'>{sub}</p>
                                                </div>
                                            </div>
                                        )
                                })}
                            </div>
                        </AccordionContent>
                    )}
                </AccordionItem>
            </Accordion>
        </>
    )
}
