import { EmptyFolder } from '@/assets/assets.export'
import { Button } from '@/components'
import { AddNewFileModal } from '@/components/Modals/Vault/AddNewFileModal'
import { CreateNewFolder } from '@/components/Modals/Vault/CreateNewFolder'
import { useGetAllVaults } from '@/services/vault/queries'
import { useMemo, useState } from 'react'

export const FileEmptyState = ({ type }: { type: string }) => {
    const [showAddNewFile, setShowAddNewFile] = useState(false)
    const { data: vaults } = useGetAllVaults({})
    const vaultsData = useMemo(() => vaults ?? [], [vaults])
    const singleVault = vaultsData?.[0]

    return (
        <div className='w-full flex h-full items-center justify-center flex-col col-span-4 mt-10'>
            <EmptyFolder className='mb-8' />
            <p className='text-[20px] text-gray7 font-medium mb-5'>No file uploaded or folder created yet</p>
            <Button
                onClick={() => {
                    setShowAddNewFile(true)
                }}
            >
                Upload files or create folder
            </Button>
            {showAddNewFile &&
                (type === 'folder' ? (
                    <CreateNewFolder closeModal={setShowAddNewFile} vault={singleVault} />
                ) : (
                    <AddNewFileModal closeModal={setShowAddNewFile} vault={singleVault} />
                ))}
        </div>
    )
}
