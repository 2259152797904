import { createQuery } from '../api/query'
import { VaultCategory, VaultItem, VaultListData } from './types'

export const orgVaultsKey = ['/vaults']
export const vaultItemsKey = ['/vault-items']
export const folderItemsKey = ['/folder-items']
export const orgVaultsCategoryKey = ['/vault/category']
export const vaultIdKey = (arg: string) => ['/single-vault', arg]
export const vaultCategoryIdKey = (arg: string) => ['/single-category', arg]
export const vaultItemIdKey = (arg: string) => ['/single-item', arg]

export const useGetAllVaults = createQuery<VaultListData[]>({
    url: '/vaults',
    key: orgVaultsKey,
})

export const useGetVault = createQuery<any>({
    url: '/vault',
    key: vaultIdKey,
})

export const useGetVaultCatgeories = createQuery<VaultCategory[]>({
    url: '/vault/categories',
    key: orgVaultsCategoryKey,
})

export const useGetVaultCatgeory = createQuery<any>({
    url: '/vault/category',
    key: vaultCategoryIdKey,
})

export const useGetVaultItems = createQuery<VaultItem[]>({
    url: '/vault/items',
    key: vaultItemsKey,
})
export const useGetFolderItems = createQuery<VaultItem[]>({
    url: '/vault/items',
    key: folderItemsKey,
})

export const useGetVaultItem = createQuery<VaultItem>({
    url: '/vault/item',
    key: vaultItemIdKey,
})
