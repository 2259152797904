import TimelineJourney from '@/components/TimelineJourney'
import { ExpenseType } from '@/types/expense'

type Props = {
    expense?: ExpenseType
}
const ExpenseDetailsTimeLine = ({ expense }: Props) => {
    const timelines = expense?.timeline?.events ?? []

    console.log(expense?.approvers)
    return (
        <section className='  bg-white rounded-2xl space-y-8'>
            <h2 className='border-gray6 font-medium mb-6 text-lg border-b pb-2'>Expense Journey timeline</h2>
            <TimelineJourney timelines={timelines} />
        </section>
    )
}

export default ExpenseDetailsTimeLine
