import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components'
import { useGetVaultCatgeories } from '@/services/vault/queries'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { CiSquarePlus } from 'react-icons/ci'
import { v4 as uuidv4 } from 'uuid'

const defaultCategory = {
    id: uuidv4(),
    name: '',
}
export type categoryProps = {
    id: string
    name: string
}
export const VaultCategories = () => {
    const [categories, setCategories] = useState<Partial<categoryProps>[]>([defaultCategory])
    const { data, isLoading } = useGetVaultCatgeories({})

    useEffect(() => {
        const mappedCategories = data?.map(category => ({
            id: category.id,
            name: category.name,
        }))
        setCategories(mappedCategories ?? [defaultCategory])
    }, [data])
    return (
        <div>
            <h1 className='text-dark font-medium text-lg'>Catgeories</h1>
            <p className='text-gray7 text-sm'>Manage vault categories</p>
            <div className={classNames('border border-gray8 rounded-xl px-3 py-4 mt-5', isLoading && 'opacity-30')}>
                <div className='grid grid-cols-2 gap-4'>
                    {categories.map((category, idx) => (
                        <div key={category.id} className='flex items-center gap-2'>
                            <p className='text-sm text-gray7 font-medium'>{idx + 1}.</p>
                            <input
                                type='text'
                                className='outline-none w-full ml-1 placeholder:text-sm h-[2.3rem] border border-[#DADCE0] rounded-lg px-3 placeholder:truncate focus:outline focus:outline-gray2'
                                required
                                defaultValue={category.name}
                                placeholder='Enter category name'
                                value={category.name}
                                onChange={e => {
                                    setCategories(field => {
                                        return field.map(data => {
                                            if (data.id === category?.id) {
                                                data.name = e.target.value
                                                return data
                                            }
                                            return data
                                        })
                                    })
                                }}
                            />
                            <span
                                className='cursor-pointer bg-[#FFEDED] rounded-full p-1'
                                onClick={() => setCategories(prev => prev?.filter(field => field.id !== category.id))}
                            >
                                <CancelIcon className='text-red h-5 w-5 !stroke-errorRed' />
                            </span>
                        </div>
                    ))}
                </div>
                <div className='mt-4 pl-7 pt-3'>
                    <button
                        className='flex items-center gap-2 border border-[#454ADE] px-3 py-2 rounded-lg bg-[#EFEFF9] text-brand text-sm font-semibold mb-8'
                        onClick={() =>
                            setCategories([
                                ...categories,
                                {
                                    ...defaultCategory,
                                    id: uuidv4(),
                                },
                            ])
                        }
                    >
                        <CiSquarePlus size={22} />
                        Add new category
                    </button>
                    <p className='h-[.5px] bg-gray8 w-full'></p>
                    <div className='mt-3 flex justify-end'>
                        <Button className=''>Save changes</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
