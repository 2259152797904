import React, { FormEvent, useState } from 'react'
import { capitalizeText } from '@/utils/string'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useVaultContext } from '@/context'
import 'react-datepicker/dist/react-datepicker.css'
import { isAfter } from 'date-fns'
import { filter_by_Props, filter_consts, FilterVaultUtils, VaultsDropdowns } from './utils'
import { EmployeeType } from '@/types/org'
import { VaultItem } from '@/services/vault/types'
import { AiOutlineClose } from 'react-icons/ai'
import { advancedFilteringConsts } from '@/context/vault'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    vaults: VaultItem[]
    orgMembers: EmployeeType[]
}

export default function FilterVault({ closeModal, vaults, orgMembers }: Props) {
    const { setFilterVault: setFilterFormState, filterVault: filterFormState } = useVaultContext()
    const [errInDate, setErrInDate] = useState<string | null>(null)

    const checkIfDateIsPastToday = (date: Date, type: string) => {
        const result = isAfter(new Date(date), new Date())
        if (result) {
            setErrInDate('Date cannot be past today')
            return
        }
        setFilterFormState(prev => ({
            ...prev,
            date_created: { ...prev.date_created, [type]: date },
        }))
        setErrInDate('')
    }
    console.log(errInDate, checkIfDateIsPastToday)

    const handleFilterForm = (event: FormEvent<HTMLFormElement | HTMLSelectElement | HTMLInputElement>) => {
        event.preventDefault()
        setFilterFormState(prev => ({ ...prev, filter_now: true }))
        closeModal(false)
    }

    const handleClearFilter = () => {
        setFilterFormState(() => ({ ...advancedFilteringConsts, filter_now: true }))
        closeModal(false)
    }
    return (
        <div className='absolute top-32 lg:top-14 right-0 z-[101]'>
            <main className='w-[94vw] lg:w-[23rem] bg-white rounded-[20px] shadow-2xl'>
                <div className='flex items-center w-full justify-between h-full pl-5 pr-3 py-3 border-b border-gray8'>
                    <h2 className='font-semibold text-[#31254B]'>Advanced filter</h2>
                    <div
                        className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                        onClick={() => {
                            closeModal(false)
                        }}
                    >
                        <AiOutlineClose fontSize={25} />
                    </div>
                </div>
                <form className=' ' onSubmit={handleFilterForm}>
                    <div className='mx-6 h-[348px] overflow-scroll pb-5'>
                        {FilterVaultUtils.filter_by.map((filterables: filter_by_Props) => (
                            <>
                                {Object.keys(filterables).map(filter_header => {
                                    console.log(
                                        VaultsDropdowns[filterables[filter_header].type as keyof typeof VaultsDropdowns]
                                    )
                                    const filter_with = filterFormState[
                                        filterables[filter_header]?.type as keyof typeof filterFormState
                                    ] as string

                                    return (
                                        <>
                                            <span></span>
                                            <span className='text-sm font-medium mb-2 inline-block mt-5'>
                                                {filter_header}
                                            </span>

                                            <Select
                                                name={filterables[filter_header].type}
                                                onValueChange={filter_by =>
                                                    setFilterFormState(prev => ({
                                                        ...prev,
                                                        [filterables[filter_header].type]: filter_by,
                                                    }))
                                                }
                                                value={filter_with?.length == 0 ? filter_consts.initial : filter_with}
                                            >
                                                <SelectTrigger className=' h-[3rem] border-[#DADCE0] text-sm'>
                                                    <SelectValue
                                                        placeholder={`Select ${filter_header.toLowerCase()}`}
                                                        className='text-sm'
                                                    />
                                                </SelectTrigger>
                                                <SelectContent className='max-h-[20rem]'>
                                                    <SelectItem value={filter_consts.initial}>
                                                        Select {filter_header.toLowerCase()}
                                                    </SelectItem>
                                                    {VaultsDropdowns[
                                                        filterables[filter_header].type as keyof typeof VaultsDropdowns
                                                    ]?.map(val => {
                                                        if (typeof val === 'string') {
                                                            return (
                                                                <SelectItem key={val} value={val}>
                                                                    {val}
                                                                </SelectItem>
                                                            )
                                                        } else {
                                                            return (
                                                                <SelectItem key={val?.type} value={val?.getDate()}>
                                                                    {val?.period}
                                                                </SelectItem>
                                                            )
                                                        }
                                                    })}
                                                    {filterables[filter_header].type === filter_consts.owner &&
                                                        orgMembers?.map(member => (
                                                            <SelectItem key={member?.id} value={member?.id}>
                                                                {capitalizeText(member?.user?.first_name)}{' '}
                                                                {capitalizeText(member?.user?.last_name)}
                                                            </SelectItem>
                                                        ))}
                                                    {filterables[filter_header].type === filter_consts.location &&
                                                        vaults?.map(vault => (
                                                            <SelectItem key={vault.id} value={vault.id}>
                                                                {vault.name}
                                                            </SelectItem>
                                                        ))}
                                                </SelectContent>
                                            </Select>
                                        </>
                                    )
                                })}
                            </>
                        ))}
                    </div>
                    <div className='flex gap-1 justify-end mb-5 border-t border-gray8 px-6 py-3'>
                        <Button
                            className='bg-transparent text-[#5E5E5E] hover:bg-transparent'
                            type='button'
                            onClick={handleClearFilter}
                            disabled
                        >
                            Clear filters
                        </Button>
                        <Button className='min-w-[103px] text-sm px-4 py-3'>Apply filters</Button>
                    </div>
                </form>
            </main>
        </div>
    )
}
