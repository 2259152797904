import { CancelIcon, PDFIcon } from '@/assets/assets.export'
import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { toastHandler } from '@/components/utils/Toast'
import useSingleFileUpload, { FileUploadFormatProps, fileEmptyState } from '@/hooks/useSingleFileUpload'
import { useConfirmInvoicePayment } from '@/services/invoice/mutations'
import { Invoice } from '@/types/invoice'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { format } from 'date-fns'
import moment from 'moment'
import { useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { TbCurrencyNaira } from 'react-icons/tb'
import ModalWrapper from '../ModalWrapper'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    invoiceDetails: Invoice
}
const RecordPayment = ({ closeModal, invoiceDetails }: Props) => {
    const formEmptyState = {
        method: '',
        amount: '',
        datePaid: '',
        payment_type: invoiceDetails?.accept_part_payment ? 'part' : 'full',
    }
    const [formState, setFormState] = useState(formEmptyState)
    const calendarRef1 = useRef<DatePicker>(null)
    const docRef = useRef<HTMLInputElement>(null)
    const [docFile, setDocFile] = useState<FileUploadFormatProps | undefined>(fileEmptyState)
    const { handleFile: uploadDocFn } = useSingleFileUpload({
        setFileUpload: setDocFile,
    })
    const { id, customer, issue_date, due_date, tax, total_amount } = invoiceDetails

    const { mutate: confirmPaymentFn, isLoading } = useConfirmInvoicePayment({
        onSuccess: () => {
            toastHandler({
                message: 'Invoice payment recorded',
                state: 'success',
            })
            closeModal(false)
        },
    })

    const isFormValid = () => {
        const filledFields = Object.values(formState).every(value => {
            return typeof value !== 'string' || value.trim() !== ''
        })
        const fileCheck = !!docFile?.file
        return filledFields && fileCheck
    }

    const handleRecordPayment = () => {
        const { amount, datePaid, method } = formState
        const formdata = new FormData()
        formdata.append('file', (docFile?.fileBeforeFormData as Blob) ?? '')
        formdata.append('invoice_id', id)
        formdata.append('payment_method', method)
        formdata.append('amount', amount)
        formdata.append('payment_date', datePaid)

        confirmPaymentFn(formdata)
    }

    const invoiceInfo = [
        { name: 'Invoice ID', desc: `INV${id?.slice(0, 3)}` },
        {
            name: 'Customer',
            desc: customer.name,
        },
        { name: 'Issue date', desc: format(new Date(issue_date), 'dd/MM/yyyy') },
        { name: 'Due date', desc: format(new Date(due_date), 'dd/MM/yyyy') },
        { name: 'Sub-total', desc: formatAmount(Number(total_amount) - Number(tax)) },
        { name: 'Tax(₦)', desc: formatAmount(tax) },
        { name: 'Total amount due', desc: formatAmount(total_amount) },
    ]

    return (
        <ModalWrapper
            variants={undefined}
            formId='recordPayment'
            closeModal={() => closeModal(false)}
            shouldConfirmClose
        >
            <main className='h-[40rem] lg:h-fit w-screen lg:w-[35rem]'>
                <section className='flex items-center border-b border-[#DADCE0]  py-3 '>
                    <span className='flex-1 text-center text-lg font-medium'>Record payment</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-1.5 w-6 h-6 mr-4 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='h-[30rem] overflow-scroll'>
                    <div className='px-5  mt-4 mb-4 '>
                        <div className='mb-5'>
                            <p className='text-base font-medium text-[#202020] '>Mark this invoice as paid</p>
                            <p className='text-[#5E5E5E] text-sm '>Record an invoice that has been paid</p>
                        </div>
                        <div className='bg-[#EFEFF9] p-5 rounded-xl text-[#202020] flex flex-col gap-1 mb-6  space-y-2'>
                            {invoiceInfo.map((field, idx) => (
                                <p key={idx} className='text-base font-medium flex gap-2'>
                                    <span className='opacity-90'>{field.name}:</span>
                                    <span className=' '>{field.desc}</span>
                                </p>
                            ))}
                        </div>
                        <form id='recordPayment' onSubmit={e => e.preventDefault()}>
                            <div className={`flex flex-col gap-x-5 gap-y-5`}>
                                <div className='flex gap-5'>
                                    <div className='flex flex-col gap-3 flex-1'>
                                        <label className='flex items-center gap-1 text-sm'>
                                            Payment method
                                            <span className=''>*</span>
                                        </label>
                                        <Select
                                            name='payment_method'
                                            onValueChange={value => {
                                                setFormState(prevState => ({
                                                    ...prevState,
                                                    method: value,
                                                }))
                                            }}
                                            value={formState.method}
                                        >
                                            <SelectTrigger
                                                className='h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'
                                                id='customer'
                                            >
                                                {formState?.method ? (
                                                    <SelectValue />
                                                ) : (
                                                    <span className='text-[#A8A8A8]'>Select payment method</span>
                                                )}
                                            </SelectTrigger>
                                            <SelectContent
                                                className=' max-h-[200px] overflow-scroll'
                                                id={`customer_content`}
                                            >
                                                {options.map((option, idx) => (
                                                    <SelectItem key={idx} value={option.toLowerCase()}>
                                                        {option}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                    <div className='flex flex-col gap-3 flex-1'>
                                        <label className='flex items-center gap-1 text-sm'>
                                            Payment Type
                                            <span className=''>*</span>
                                        </label>
                                        <Select
                                            name='payment_type'
                                            onValueChange={value => {
                                                console.log(value)
                                                setFormState(prevState => ({
                                                    ...prevState,
                                                    payment_type: value,
                                                }))
                                            }}
                                            value={formState.payment_type}
                                        >
                                            <SelectTrigger
                                                className='h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'
                                                id='payment_type'
                                            >
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent
                                                className=' max-h-[200px] overflow-scroll'
                                                id={`payment_type`}
                                            >
                                                <SelectItem value={'full'}>Full payment</SelectItem>
                                                <SelectItem value={'part'}>Part payment</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                                <div className='flex gap-5'>
                                    <div className='flex flex-col gap-3'>
                                        <label className='flex items-center gap-1 text-sm'>
                                            Amount paid
                                            <span className=''>*</span>
                                        </label>
                                        <Input
                                            type={'text'}
                                            name='amount'
                                            value={formatAmount(formState.amount).replaceAll('₦', '')}
                                            placeholder='Enter amount'
                                            className='border-[#DADCE0] h-[3rem] disabled:bg-[#F9F9F9]'
                                            onChange={e => {
                                                let value = e.target.value
                                                value = formatAmountWithoutSymbols(value)

                                                setFormState(prevState => ({
                                                    ...prevState,
                                                    amount: value,
                                                }))
                                            }}
                                            leftIcon={<TbCurrencyNaira fontSize={25} color={'#838383'} />}
                                        />
                                    </div>
                                    <div className='flex flex-col gap-3'>
                                        <label className='flex items-center gap-1 text-sm'>
                                            Payment date
                                            <span className=''>*</span>
                                        </label>
                                        <CustomDatePicker
                                            ref={calendarRef1}
                                            className=''
                                            placeholderText='DD/MM/YYYY'
                                            maxDate={moment().add(1, 'days').toDate()}
                                            onChange={date =>
                                                setFormState(prev => ({
                                                    ...prev,
                                                    datePaid: date! as unknown as string,
                                                }))
                                            }
                                            selected={formState.datePaid as unknown as Date}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-3'>
                                    <label className='flex items-center gap-1 text-sm'>
                                        Proof of payment
                                        <span className=''>*</span>
                                    </label>
                                    <>
                                        <div
                                            className={`flex items-center ${
                                                docFile?.file_name ? '' : ' justify-center'
                                            } gap-[128px] border border-dashed border-[#DADCE0] rounded-lg p-4 `}
                                        >
                                            <label htmlFor='first_doc' className='cursor-pointer'>
                                                {!docFile?.file_name && (
                                                    <>
                                                        <p className='text-[#A8A8A8] text-lg font-medium text-center'>
                                                            Upload bill proof
                                                            <br />
                                                            Max 2MB | Accepts JPEG, PNG, and PDF formats
                                                        </p>
                                                    </>
                                                )}
                                                {docFile?.file_name && (
                                                    <div className='bg-white flex justify-between items-center rounded-lg w-[480px]'>
                                                        <div className='flex items-center gap-4'>
                                                            <div className=''>
                                                                {docFile?.fileType === 'pdf' ? (
                                                                    <PDFIcon className='w-[4.5rem] h-24 inline-block rounded-lg' />
                                                                ) : (
                                                                    <img
                                                                        src={docFile.fileUrl}
                                                                        alt=''
                                                                        className='w-[4.5rem] h-24 rounded-lg'
                                                                    />
                                                                )}
                                                            </div>

                                                            <div className='text-lg font-semibold'>
                                                                <h2 className='text-[#838383] truncate'>
                                                                    {docFile.file_name.slice(0, 30)}.{docFile.fileType}
                                                                </h2>
                                                                <p className='text-[#202020]'>{docFile.size}</p>
                                                            </div>
                                                        </div>

                                                        <span
                                                            className='cursor-pointer'
                                                            onClick={() => setDocFile(fileEmptyState)}
                                                        >
                                                            {
                                                                <CancelIcon className='rounded-full bg-[#F9F0EF]  text-red p-[0.35rem] h-10 w-10 !stroke-[#872D10]' />
                                                            }
                                                        </span>
                                                    </div>
                                                )}
                                                <input
                                                    type='file'
                                                    name='file'
                                                    hidden
                                                    id='first_doc'
                                                    ref={docRef}
                                                    onChange={e => {
                                                        uploadDocFn(e)
                                                    }}
                                                />
                                            </label>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <section className='flex items-center justify-end border-t border-[#DADCE0] p-3 pr-4 pb-3  '>
                    <Button
                        className='bg-[#19C37D] text-white  h-[3rem]  rounded-lg text-xs min-w-[130px] px-3'
                        size={'sm'}
                        onClick={handleRecordPayment}
                        disabled={!isFormValid()}
                        loading={isLoading}
                    >
                        Confirm payment
                    </Button>
                </section>
            </main>
        </ModalWrapper>
    )
}

export default RecordPayment

const options = ['Card payment', 'Bank transfer', 'Cash']
