import { Button } from '@/components'
import LeaveRequestModal from '@/components/Modals/Payroll/LeaveRequest/RequestModal'
import ProgressBar from '@/components/ProgressBar'
import { useAuthContext } from '@/context'
import { useGetLeaveSettings, useGetUserLeaveHistory } from '@/services/payroll/queries'
import { useMemo, useState } from 'react'
import UserLeaveTable from '../../components/UserLeaveTable'
import { LeaveSettingsType } from '@/types/payroll'

const UserLeaveManagement = () => {
    const [requestLeave, setRequestLeave] = useState(false)
    const { selectedAccount } = useAuthContext()

    const { data, isLoading } = useGetUserLeaveHistory({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })
    const userLeave = useMemo(() => data ?? [], [data])

    const { data: leaveSettings } = useGetLeaveSettings({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })

    const utilsLeave = [
        {
            type: 'Vacation',
            total: leaveSettings?.[0]?.vacation,
            color: '#F4FAFF',
        },
        {
            type: 'Sick days',
            total: leaveSettings?.[0]?.sick_days,
            color: '#FFEFE7',
        },
        {
            type: 'Personal',
            total: leaveSettings?.[0]?.miscellaneous,
            color: '#F4FFFA',
        },
        {
            type: 'Maternity / Paternity',
            total: leaveSettings?.[0]?.parental,
            color: '#EFEFF9',
        },
    ]

    if (isLoading) return <ProgressBar />
    return (
        <div className='h-[90vh] overflow-scroll pb-48'>
            <div className='grid grid-cols-2 md:grid-cols-5 gap-2 lg:gap-4 mt-5 mb-10'>
                {utilsLeave.map((leave, idx) => (
                    <div
                        key={idx}
                        className={`border border-[#DADCE0] bg-[${leave.color}] rounded-lg py-3  lg:py-5 pl-5   flex flex-col gap-2 lg:gap-3`}
                    >
                        <span className='text-[#5E5E5E] text-sm lg:text-base'>{leave.type}</span>
                        <span className='text-xl md:text-2xl lg:text-3xl font-semibold '>
                            {leave.total} <span className='text-sm lg:text-base font-medium'>days</span>
                        </span>
                    </div>
                ))}
            </div>
            <div className='flex flex-col md:flex-row justify-between gap-4'>
                <div className=''>
                    <h1 className='text-lg lg:text-xl font-semibold mb-1 md:mb-3'>My leave requests</h1>
                    <p className='text-[#5E5E5E] font-medium text-sm md:text-base'>
                        This is where you see your leave history and requests
                    </p>
                </div>
                <Button className='min-w-full md:min-w-[50px]' onClick={() => setRequestLeave(true)}>
                    Request leave
                </Button>
            </div>
            <section className='overflow-x-scroll'>
                <div className='min-w-[50rem] md:min-w-full overflow-x-scroll'>
                    <UserLeaveTable leaveRequests={userLeave!} />
                </div>
            </section>
            {requestLeave && (
                <LeaveRequestModal
                    closeModal={setRequestLeave}
                    leaveSettings={leaveSettings?.[0] as LeaveSettingsType}
                />
            )}
        </div>
    )
}

export default UserLeaveManagement
