import { useContext } from 'react'
import { AuthContext } from './auth'
import { ExpenseContext } from './expense'
import { EmployeesContext } from './employees'
import { SettingsContext } from './settings'
import { RolesContext } from './roles'
import { VendorContext } from './vendors'
import { TransactionContext } from './transactions'
import { PayrollContext } from './payroll'
import { BudgetsContext } from './budget'
import { InvoiceContext } from './InvoiceReminder'
import { ImpersonationContext } from './impersonation'
import { BillPaymentContext } from './bill-payment'
import { AccountingContext } from './accounting'
import { VaultContext } from './vault'
import { CurrencyContext } from './currency'

export const useAuthContext = () => useContext(AuthContext)
export const useExpenseContext = () => useContext(ExpenseContext)
export const useEmployeesContext = () => useContext(EmployeesContext)
export const useSettingsContext = () => useContext(SettingsContext)
export const useRolesContext = () => useContext(RolesContext)
export const useVendorContext = () => useContext(VendorContext)
export const useTransactionContext = () => useContext(TransactionContext)
export const usePayrollContext = () => useContext(PayrollContext)
export const useBudgetContext = () => useContext(BudgetsContext)
export const useInvoiceContext = () => useContext(InvoiceContext)
export const useBillPaymentContext = () => useContext(BillPaymentContext)
export const useImpersonationContext = () => useContext(ImpersonationContext)
export const useAccountingContext = () => useContext(AccountingContext)
export const useVaultContext = () => useContext(VaultContext)
export const useCurrencyContext = () => useContext(CurrencyContext)
