import { Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { TbCurrencyNaira } from 'react-icons/tb'
import { FormValues, createBudgetFields } from './utils'

import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import SelectWIthChip from '@/components/SelectWithChip'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { useAuthContext } from '@/context'
import { useGetOrgMembers, useGetTeamsInOrg } from '@/services/employees/queries'
import { EmployeeType, RoleEnum } from '@/types/org'
import { capitalizeText } from '@/utils/string'
import { AlertCircleIcon } from 'lucide-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { PlusSquare } from 'lucide-react'
import CreateTeamModal from '../Employee/CreateTeamModal/CreateTeamModal'
import { useGetOrgCategories } from '@/services/categories/queries'

type Props = {
    setFormState: React.Dispatch<React.SetStateAction<FormValues>>
    formState: FormValues
}
const CreateBudget1 = ({ formState, setFormState }: Props) => {
    const { selectedAccount } = useAuthContext()
    const divRef = useRef<HTMLDivElement | null>(null)
    const calendarRef1 = useRef<DatePicker>(null)
    const calendarRef2 = useRef<DatePicker>(null)
    const [openSelectContainer, setOpenSelectContainer] = useState(false)
    const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false)

    // const [selectedEmployees, setSelectedEmployees] = useState<Record<'label' | 'value', string>[]>([])

    const { data: categories } = useGetOrgCategories()
    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const { data: teamsInOrg } = useGetTeamsInOrg({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
        },
    })

    const orgMembers = useMemo(() => org_members ?? [], [org_members])
    const allCategories = useMemo(() => categories ?? [], [categories])

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        name: string
    ) => {
        const { value } = e.target
        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    //gets the users in a team
    useEffect(() => {
        if (formState.users === 'team' && teamsInOrg) {
            setFormState(prev => ({
                ...prev,
                allocatedAmount: teamsInOrg
                    ?.filter(team => team._id === formState.team_id)[0]
                    ?.users?.map(member => ({ member: member?.account as EmployeeType, amount: '0' })),
            }))
            return
        }

        setFormState(prev => ({ ...prev, allocatedAmount: orgMembers.map(member => ({ member, amount: '0' })) }))
    }, [formState.users, teamsInOrg, formState.team_id])

    useEffect(() => {
        if (formState.users == 'team' && divRef.current) {
            divRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [formState.users])

    // console.log(teamsInOrg)
    return (
        <div className=''>
            <div className='mt-4'>
                <h1 className='text-[#31254B] text-2xl mb-1 font-semibold'>Budget Information</h1>
                <p className='text-[#202020]'>Enter the appropriate details for this budget</p>
            </div>
            <div className='mt-10'>
                <form action='' id='budgetForm'>
                    <div className='grid grid-cols-2 gap-6 mb-6'>
                        {createBudgetFields.map((field, idx) => {
                            if (
                                formState?.reset !== 'one-off' &&
                                (field.name === 'start_date' || field.name === 'end_date')
                            )
                                return

                            if (formState?.reset === 'one-off' && field.name === 'unused_budget') return

                            return (
                                <div
                                    className={`flex flex-col flex-1 gap-3 ${
                                        field.name === 'manager' ? 'col-span-2' : ''
                                    } ${field.name === 'unused_budget' ? 'col-span-2' : ''} ${
                                        field.fieldType === 'textarea' ? 'col-span-2' : ''
                                    }`}
                                    key={idx}
                                >
                                    <label className='flex items-center gap-1'>
                                        {field.title}
                                        {field.name === 'purpose' ? '' : <span className='text-[red]'>*</span>}

                                        {field.name === 'manager' && (
                                            <TooltipProvider delayDuration={10}>
                                                <Tooltip>
                                                    <TooltipTrigger className='mr-2' type='button'>
                                                        <AlertCircleIcon size={15} />
                                                    </TooltipTrigger>

                                                    <TooltipContent>
                                                        <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                            This user manages this budget and can assign this budget to
                                                            users
                                                        </p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        )}
                                    </label>
                                    {field.fieldType === 'amount' && (
                                        <Input
                                            type={'text'}
                                            name={field.name}
                                            value={formatAmount(
                                                formState[field.name as keyof typeof formState] as string
                                            ).replaceAll('₦', '')}
                                            placeholder={field.placeholder}
                                            className='border-[#DADCE0]  h-[3rem] disabled:bg-[#F9F9F9]'
                                            onChange={e => {
                                                const { name } = e.target
                                                let value = e.target.value

                                                if (field.fieldType === 'amount') {
                                                    value = formatAmountWithoutSymbols(value)
                                                }

                                                setFormState(prevState => ({
                                                    ...prevState,
                                                    [name]: value,
                                                }))
                                            }}
                                            leftIcon={<TbCurrencyNaira fontSize={25} color={'#838383'} />}
                                        />
                                    )}
                                    {field.fieldType === 'text' && (
                                        <Input
                                            type='text'
                                            name='merchant'
                                            value={formState[field.name as keyof typeof formState] as string}
                                            placeholder={field.placeholder}
                                            className='border-[#DADCE0]  h-[3rem]'
                                            onChange={e => handleInputChange(e, field.name)}
                                        />
                                    )}

                                    {field.name === 'manager' && (
                                        <SelectWIthChip
                                            options={orgMembers
                                                .filter(
                                                    members =>
                                                        ![RoleEnum.EMPLOYEE, RoleEnum.CONTRACTOR].includes(
                                                            members?.role.name
                                                        )
                                                )
                                                .map(members_data => ({
                                                    label: `${capitalizeText(
                                                        members_data?.user?.first_name
                                                    )} ${capitalizeText(members_data?.user?.last_name)}`,
                                                    value: members_data?._id ?? (members_data?.id as string),
                                                }))}
                                            placeholder='Who will manage this budget?'
                                            onChange={members => setFormState(prev => ({ ...prev, manager: members }))}
                                            value={formState.manager}
                                            className='h-[3.5rem]'
                                        />
                                    )}
                                    {field.fieldType === 'dropdown' && (
                                        <Select
                                            name={field.name}
                                            onValueChange={value => {
                                                setFormState(prevState => ({
                                                    ...prevState,
                                                    [field.name]: value,
                                                }))
                                            }}
                                            value={formState[field.name as keyof typeof formState] as string}
                                        >
                                            <SelectTrigger
                                                className={`${
                                                    field.name === 'unused_budget' ? 'py-7 lg:h-[3rem]' : 'h-[3rem]'
                                                }  border-[#DADCE0] bg-base_gray_100 text-[13px]`}
                                                variant={
                                                    (formState[field.name as keyof typeof formState] as string).length >
                                                    0
                                                        ? 'selected'
                                                        : 'new'
                                                }
                                            >
                                                {formState?.[field?.name as keyof typeof formState] ? (
                                                    <SelectValue className='' />
                                                ) : (
                                                    <span>{field.placeholder}</span>
                                                )}
                                            </SelectTrigger>

                                            <SelectContent
                                                className={`w-[18.9rem] max-h-[200px] overflow-scroll ${
                                                    field.name === 'unused_budget' ? 'w-[24rem] lg:w-[43rem]' : ''
                                                }`}
                                            >
                                                {field.name === 'manager'
                                                    ? orgMembers
                                                          .filter(
                                                              members =>
                                                                  ![RoleEnum.EMPLOYEE, RoleEnum.CONTRACTOR].includes(
                                                                      members?.role.name
                                                                  )
                                                          )
                                                          .map(member => {
                                                              return (
                                                                  <SelectItem value={member.id} key={member.id}>
                                                                      {capitalizeText(member.user.first_name)}{' '}
                                                                      {capitalizeText(member.user.last_name)}
                                                                  </SelectItem>
                                                              )
                                                          })
                                                    : field.name === 'category'
                                                      ? allCategories.map(category => (
                                                            <SelectItem value={category.id} key={category.id}>
                                                                {capitalizeText(category.label)}{' '}
                                                            </SelectItem>
                                                        ))
                                                      : field.fieldOptions?.map(option => (
                                                            <SelectItem value={option?.desc} key={option?.desc}>
                                                                <div
                                                                    dangerouslySetInnerHTML={{ __html: option.name }}
                                                                />
                                                            </SelectItem>
                                                        ))}
                                            </SelectContent>
                                        </Select>
                                    )}
                                    {field.fieldType === 'date' && (
                                        <CustomDatePicker
                                            {...field}
                                            ref={field?.name === 'start_date' ? calendarRef1 : calendarRef2}
                                            onChange={date => {
                                                setFormState(prev => ({
                                                    ...prev,
                                                    [field.name]: date! as unknown as string,
                                                }))
                                            }}
                                            disabled={field.name === 'end_date' && !formState.start_date ? true : false}
                                            minDate={
                                                field.name == 'end_date'
                                                    ? new Date(formState?.start_date ?? '')
                                                    : undefined
                                            }
                                            selected={
                                                formState[field.name as keyof typeof formState]
                                                    ? new Date(
                                                          formState[field.name as keyof typeof formState] as string
                                                      )
                                                    : undefined
                                            }
                                        />
                                    )}
                                    {field.fieldType === 'textarea' && (
                                        <Textarea
                                            maxLength={120}
                                            name='merchant'
                                            value={formState[field.name as keyof typeof formState] as string}
                                            placeholder={field.placeholder}
                                            className='border-[#DADCE0]  h-[3rem] resize-none'
                                            onChange={e => handleInputChange(e, field.name)}
                                            cols={2}
                                            rows={1}
                                        />
                                    )}
                                </div>
                            )
                        })}
                        <div className='flex'>
                            <div className='flex flex-col flex-1 gap-3'>
                                <label className='flex items-center gap-1'>
                                    Budget users
                                    <span className='text-[red]'>*</span>
                                    <TooltipProvider delayDuration={10}>
                                        <Tooltip>
                                            <TooltipTrigger className='mr-2' type='button'>
                                                <AlertCircleIcon size={15} />
                                            </TooltipTrigger>

                                            <TooltipContent>
                                                <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                    These are the users the budget is allocated to
                                                    <br />
                                                    The budget will not show up when assigning a budget <br /> to a user
                                                    who is not included in the option selected
                                                </p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </label>
                                <RadioGroup
                                    onValueChange={value => {
                                        setFormState(prevState => ({
                                            ...prevState,
                                            users: value,
                                        }))
                                    }}
                                    defaultValue={formState.users}
                                    className='flex gap-5 mt-3'
                                >
                                    <label
                                        htmlFor={'team'}
                                        className='flex gap-2 items-center text-[#5E5E5E] font-medium text-sm cursor-pointer'
                                        // key={types}
                                    >
                                        <RadioGroupItem value={'team'} id={'team'} />
                                        Team
                                    </label>
                                    {/* <label
                                        htmlFor={'users'}
                                        className='flex gap-2 items-center text-[#5E5E5E] font-medium text-sm cursor-pointer'
                                        // key={types}
                                    >
                                        <RadioGroupItem value={'user'} id={'users'} />
                                        Specific users
                                    </label> */}
                                    <label
                                        htmlFor={'all'}
                                        className='flex gap-2 items-center text-[#5E5E5E] font-medium text-sm cursor-pointer'
                                        // key={types}
                                    >
                                        <RadioGroupItem value={'all'} id={'all'} />
                                        All Users
                                    </label>
                                </RadioGroup>
                            </div>
                        </div>
                    </div>
                    {/* <div className='flex flex-col flex-1 gap-3 my-6'>
                        <label className='flex items-center gap-1'>
                            Description
                            <span className='text-[red]'>*</span>
                            <span className='ml-auto text-xs'>{formState.desc.length}/120</span>
                        </label>
                        <Textarea
                            name='description'
                            placeholder='Describe expense'
                            className='border border-[#DADCE0] pt-4 min-h-[5.6rem] text-18px resize-none'
                            value={formState.desc}
                            onChange={e => handleInputChange(e, 'desc')}
                            maxLength={120}
                        />
                    </div> */}
                    {formState.users == 'team' && (
                        <div className='mt-6 mb-10' ref={divRef}>
                            <div className='flex flex-col flex-1 gap-3'>
                                <label className='flex items-center gap-1'>
                                    Select team
                                    <span className='text-[red]'>*</span>
                                </label>
                                <Select
                                    name='team_name'
                                    onValueChange={value => {
                                        setFormState(prevState => ({
                                            ...prevState,
                                            team_id: value,
                                        }))
                                    }}
                                    value={formState.team_id}
                                    onOpenChange={setOpenSelectContainer}
                                    open={openSelectContainer}
                                >
                                    <SelectTrigger
                                        className=' h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'
                                        variant={formState.team_id !== 'none' ? 'selected' : 'new'}
                                    >
                                        {formState.team_id !== 'none' ? (
                                            <SelectValue className='' />
                                        ) : (
                                            <span className='text-gray7'>Choose team for allocation</span>
                                        )}

                                        {/* <SelectValue placeholder='Choose team for allocation' /> */}
                                    </SelectTrigger>
                                    <SelectContent className='w-[38rem] max-h-[200px] overflow-scroll'>
                                        <button
                                            className='flex items-center gap-1 pl-7 py-1 text-sm font-medium hover:bg-[#EFEFF9] w-full'
                                            onClick={() => {
                                                setIsCreateTeamModalOpen(true)
                                                setOpenSelectContainer(false)
                                            }}
                                        >
                                            <PlusSquare size={15} /> Create a new team
                                        </button>
                                        {teamsInOrg?.map(team => (
                                            <SelectItem value={team._id} key={team._id}>
                                                {capitalizeText(team.name)}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    )}
                </form>
            </div>
            {isCreateTeamModalOpen && <CreateTeamModal closeModal={setIsCreateTeamModalOpen} members={orgMembers} />}
        </div>
    )
}

export default CreateBudget1
