import { PlusSquare } from 'lucide-react'
import { useMemo, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { DocumentsNav } from '../../components/DocumentsNav/DocumentsNav'
import { AddNewFileModal } from '@/components/Modals/Vault/AddNewFileModal'
import { CreateNewFolder } from '@/components/Modals/Vault/CreateNewFolder'
import { UploadingPopup } from '@/components/Modals/Vault/UploadingPopup'
import { useGetAllVaults, useGetVaultItems } from '@/services/vault/queries'
import ProgressBar from '@/components/ProgressBar'
import { NoVaultComponent } from '../../components/NoVaultComponent'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ItemsView } from '../../components/FolderNavigation/ItemsView'
import FilterVault from '../../components/AdvancedFilter/VaultsAdvanceFilter'
import { VaultItem } from '@/services/vault/types'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useAuthContext } from '@/context'
import { SlidersHorizontal } from 'lucide-react'
import { Menu } from '@headlessui/react'
import { FolderIcon, LockedFolder, NewDoc, NewFolder } from '@/assets/assets.export'

export const VaultDocuments = () => {
    const [searchQuery, setSearchQuery] = useState('')
    const [createFolder, setCreateFolder] = useState(false)
    const [showAddNewFile, setShowAddNewFile] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const { selectedAccount } = useAuthContext()
    const [searchParams] = useSearchParams()

    console.log(searchQuery)
    const { data: vaults, isLoading: isLoadingVaults } = useGetAllVaults({})
    const vaultsData = useMemo(() => vaults ?? [], [vaults])
    const singleVault = vaultsData?.[0]

    const { data: vaultItems, isLoading: isLoadingVaultItems } = useGetVaultItems({
        queryParams: {
            vault: singleVault?.id,
            parent: searchParams.get('folder') ?? singleVault?.id,
        },
        enabled: !!vaults && !searchParams.get('folder'),
    })

    const { data: employees } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    const orgMembers = useMemo(() => employees ?? [], [employees])
    const navigate = useNavigate()

    const folders = vaultItems?.filter(item => item.type === 'folder')

    if (isLoadingVaults || isLoadingVaultItems) return <ProgressBar />
    // h-screen overflow-scroll
    return (
        <section className={` pb-6`}>
            <div className='block lg:flex justify-between mb-6 lg:mb-0 relative'>
                <div className='mb-2 lg:mb-6'>
                    <h1 className='text-dark font-medium text-lg'>Welcome to vault</h1>
                    <p className='text-gray7 text-sm'>
                        Easily upload, share, and manage all your business documents in one place.
                    </p>
                </div>
                {singleVault && (
                    <div className='border border-[#DADCE0] h-[45px] px-2 w-full lg:w-[30%] rounded-lg flex items-center justify-between overflow-hidden bg-white'>
                        <div className='flex gap-1 items-center h-full'>
                            <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full mx-1 lg:mx-0'>
                                <CiSearch fontSize={25} />
                            </div>
                            <input
                                type='text'
                                className='h-full w-[60%] outline-none placeholder:text-xs'
                                onChange={e => setSearchQuery(e.target.value)}
                                // onKeyDown={handleKeyDown}
                                placeholder='Search by name'
                            />
                        </div>
                        <SlidersHorizontal
                            color='#5E5E5E'
                            onClick={() => {
                                if (!(vaultItems?.length === 0)) {
                                    setShowFilter(true)
                                }
                            }}
                            className={`cursor-pointer ${vaultItems?.length === 0 && 'opacity-30'}`}
                        />
                    </div>
                )}
                <UploadingPopup />
                {showFilter && (
                    <FilterVault
                        closeModal={setShowFilter}
                        vaults={vaultItems as VaultItem[]}
                        orgMembers={orgMembers}
                    />
                )}
            </div>
            <div className='flex flex-col lg:flex-row gap-5 justify-between items-center lg:items-start '>
                {vaultsData?.length > 0 && (
                    <div className='w-full lg:w-[23%] bg-white p-2 lg:p-5 flex items-center flex-col justify-between gap-3 rounded-xl lg:min-h-[598px]'>
                        <div className='flex flex-row items-center lg:flex-col gap-3 w-full'>
                            <Menu as='div' className='relative w-[88px] lg:w-full'>
                                <Menu.Button className='bg-[#EFEFF9] text-sm font-semibold py-2 flex items-center justify-center gap-2 w-[88px] lg:w-full rounded-lg px-3 text-brand border border-transparent hover:border-brand h-fit'>
                                    <PlusSquare size={20} /> New
                                </Menu.Button>
                                <Menu.Items className='w-[165px] lg:w-full shadow-xl flex flex-col absolute left-0 py-2 px-0 rounded-lg justify-center border border-[#DADCE0] bg-white mt-2 z-10'>
                                    <Menu.Item>
                                        <span
                                            className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2'
                                            onClick={() => {
                                                setShowAddNewFile(true)
                                            }}
                                        >
                                            <NewDoc />
                                            Upload file(s)
                                        </span>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <span
                                            className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2'
                                            onClick={() => {
                                                setCreateFolder(true)
                                            }}
                                        >
                                            <NewFolder />
                                            Create folder
                                        </span>
                                    </Menu.Item>
                                </Menu.Items>
                            </Menu>
                            <div className='flex flex-row w-full lg:flex-col gap-3 justify-between'>
                                <p
                                    className={`cursor-pointer text-sm font-semibold ${searchParams.size === 0 && 'text-brand'} border-l border-gray8 pl-3 lg:pl-0 lg:border-none`}
                                    onClick={() => navigate('')}
                                >
                                    General
                                </p>
                                <p className='text-sm font-medium text-gray7 hidden lg:flex items-center gap-2'>
                                    <FolderIcon /> All files & folders
                                </p>
                                <p className='text-sm font-medium text-gray7 hidden lg:flex items-center gap-2'>
                                    <LockedFolder /> Personal Folder
                                </p>
                                <p className='text-sm font-medium text-gray7 hidden lg:flex items-center gap-2'>
                                    <FolderIcon /> Shared to others
                                </p>
                                <DocumentsNav folders={folders ?? []} />
                            </div>
                        </div>
                        <div className='hidden lg:block w-full border border-gray8 p-3 pt-4 rounded-lg'>
                            <div className='w-full h-2 bg-[#F2F2F2] rounded-[40px]'>
                                <div className='w-2/5 bg-[#5C9BFF] rounded-l-[40px] h-2'></div>
                            </div>
                            <p className='mt-3 text-sm text-gray7 font-medium'>12.54 GB of 100 GB used</p>
                            <button className='py-2 w-full rounded-lg text-center text-sm font-medium text-gray7 border border-gray8 mt-6'>
                                Get more storage
                            </button>
                        </div>
                    </div>
                )}
                <div
                    className={`${vaultsData?.length === 0 ? 'w-full mt-8' : 'w-full lg:w-[74%]'} bg-white rounded-2xl pt-5 pb-8 px-6 lg:min-h-[598px]`}
                >
                    {vaultsData?.length === 0 ? (
                        <>
                            <NoVaultComponent />
                        </>
                    ) : (
                        <>
                            <ItemsView />
                        </>
                    )}
                </div>
            </div>
            {showAddNewFile && <AddNewFileModal closeModal={setShowAddNewFile} vault={singleVault} />}
            {createFolder && <CreateNewFolder closeModal={setCreateFolder} vault={vaultsData?.[0]} />}
        </section>
    )
}
