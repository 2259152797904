import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { VaultDocuments } from './partials/VaultDocuments/VaultDocuments'
import { VaultSettings } from './partials/VaultSettings/VaultSettings'
import { useGetAllVaults } from '@/services/vault/queries'
import { useMemo } from 'react'
import ProgressBar from '@/components/ProgressBar'

const vault_routes_const = {
    documents: 'documents',
    settings: 'settings',
}
export default function Vault() {
    const { vault_subRoute } = useParams()
    const navigate = useNavigate()
    const { data: vaults, isLoading: isLoadingVaults } = useGetAllVaults({})
    const vaultsData = useMemo(() => vaults ?? [], [vaults])
    if (isLoadingVaults) return <ProgressBar />
    return (
        <section className='h-full mt-5'>
            <section className='px-2 lg:px-0 pb-[7rem]'>
                <Tabs defaultValue={vault_subRoute} value={vault_subRoute}>
                    <TabsList className='border-none'>
                        <TabTriggersContainer>
                            <TabsTrigger
                                value={vault_routes_const.documents}
                                onClick={() => navigate(`/dashboard/vault/${vault_routes_const.documents}`)}
                                className={`relative border-none ${
                                    vault_routes_const.documents === vault_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {vault_routes_const.documents === vault_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    Documents
                                </motion.span>
                            </TabsTrigger>
                            {vaultsData?.length > 0 && (
                                <TabsTrigger
                                    value={vault_routes_const.settings}
                                    onClick={() => navigate(`/dashboard/vault/${vault_routes_const.settings}`)}
                                    className={`relative border-none ${
                                        vault_routes_const.settings === vault_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {vault_routes_const.settings === vault_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                        Settings
                                    </motion.span>
                                </TabsTrigger>
                            )}
                        </TabTriggersContainer>
                    </TabsList>
                    <TabsContent value={vault_routes_const.documents}>
                        <PageContent>
                            <VaultDocuments />
                        </PageContent>
                    </TabsContent>
                    {vaultsData?.length > 0 && (
                        <TabsContent value={vault_routes_const.settings}>
                            <PageContent>
                                <VaultSettings />
                            </PageContent>
                        </TabsContent>
                    )}
                </Tabs>
            </section>
        </section>
    )
}
