import { GreaterThan } from '@/assets/assets.export'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { noop } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { useNavigate } from 'react-router-dom'
import filter from '@/assets/filter.svg'
import PaymentHistoryTable from './PaymentHistoryTable'
import { useAuthContext } from '@/context'
import { useGetOrgTransactions } from '@/services/transactions/queries'
import ProgressBar from '@/components/ProgressBar'
import { TransactionType } from '@/types/transactions'

export const PlanPaymentHistory = () => {
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useState('')
    const { selectedAccount } = useAuthContext()
    const { data, isLoading } = useGetOrgTransactions({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            type: 'Subscription Payment',
            limit: '15',
        },
    })
    const subTransactions = useMemo(() => data?.results ?? ([] as TransactionType[]), [data])
    if (isLoading) return <ProgressBar />
    return (
        <main className='bg-[#F9F9F9] h-full overflow-y-scroll'>
            <section className='font-medium pr-6 pb-4 pt-4 ml-[1.4rem] lg:ml-[2.9rem] mx-auto flex gap-1 overflow-x-scroll'>
                <span
                    onClick={() => navigate('/dashboard/settings/company')}
                    className='cursor-pointer whitespace-nowrap'
                >
                    Company settings
                </span>
                <span className='text-[#838383] flex gap-1 whitespace-nowrap'>
                    {' '}
                    <GreaterThan />
                    <span
                        onClick={() => navigate('/dashboard/settings/company/plan-pricing')}
                        className='cursor-pointer text-[#202020]'
                    >
                        Pricing
                    </span>
                    <GreaterThan />
                    Payment history
                </span>
            </section>
            <hr className='text-[#DADCE0] ' />
            <section className='pt-4 mb-[8rem] ml-[1.4rem] lg:ml-[3rem] lg:max-w-[90rem] mx-auto overflow-y-scroll'>
                <main>
                    <section className='flex justify-between items-end'>
                        <div className='flex flex-col'>
                            <span className='font-semibold text-lg'>Subscription</span>
                            <span className='text-[#5E5E5E] font-medium text-sm mt-2'>
                                Manage your subscription and upgrade plans when necessary.
                            </span>
                        </div>
                    </section>
                    <div className='mt-7 bg-white rounded-xl py-8 pb-1 lg:pb-8 px-2 lg:px-5 mr-7'>
                        <div className='flex justify-between items-end flex-wrap gap-4'>
                            <div className=''>
                                <p className='text-gray7 mb-2 text-sm font-medium'>Total amount paid</p>
                                <p className='font-semibold'>{'N/A'}</p>
                            </div>
                            <div>
                                <Select onValueChange={noop} value='all_budgets'>
                                    <SelectTrigger className='w-[9rem] h-[45px] border-gray8 bg-transparent text-[#5E5E5E]'>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-[10rem]'>
                                        <SelectItem value='all_budgets'>All time</SelectItem>
                                        <SelectItem value='1 month'>Last Month</SelectItem>
                                        <SelectItem value='3 months'>Last 3 Months</SelectItem>
                                        <SelectItem value='6 months'>Last 6 Months</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h1 className='pb-2 text-lg font-semibold border-b border-gray8'>Payment history</h1>
                            <div className='flex items-center justify-between mt-6'>
                                <div className='border border-[#DADCE0] h-[45px] w-2/3 lg:w-[30%] rounded-lg flex items-center overflow-hidden'>
                                    <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full mr-1 lg:mr-0'>
                                        <CiSearch fontSize={25} />
                                    </div>
                                    <input
                                        type='text'
                                        placeholder='Search by Initiator'
                                        className='h-full w-[60%] outline-none placeholder:text-xs'
                                        onChange={e => setSearchQuery(e.target.value)}
                                        // onKeyDown={handleKeyDown}
                                        value={searchQuery}
                                    />
                                </div>
                                <div className='flex items-center gap-5'>
                                    <div className='h-[35px] w-[35px] border border-[#DADCE0] rounded-lg flex items-center justify-center p-1.5 cursor-pointer'>
                                        <img src={filter} alt='button' />
                                    </div>
                                </div>
                            </div>
                            <div className='my-6 mb-1 overflow-x-scroll'>
                                <PaymentHistoryTable payments={subTransactions} />
                            </div>
                        </div>
                    </div>
                </main>
            </section>
        </main>
    )
}
