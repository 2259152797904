import { CancelIcon, FolderIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { useRef, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { FileUploadFormatProps, useMultipleFileUpload } from '@/hooks/useSingleFileUpload'
import { Button } from '@/components/Button'
import { useCreateVaultItem, useGenerateSignedUrl } from '@/services/vault/mutations'
import { signedUrlContext, VaultListData } from '@/services/vault/types'
import { useVaultContext } from '@/context'
import { v4 as uuidv4 } from 'uuid'
import { useGetVaultItems } from '@/services/vault/queries'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    vault?: VaultListData
}

export const AddNewFileModal = ({ closeModal, vault }: Props) => {
    const [formState, setFormState] = useState({
        folder: '',
    })
    const { setSignedUrlData } = useVaultContext()
    const docRef = useRef<HTMLInputElement>(null)
    const [docFiles, setDocFiles] = useState<FileUploadFormatProps[] | undefined>([])
    const { handleFiles: uploadDocFn } = useMultipleFileUpload({
        setFilesUpload: setDocFiles,
    })
    const removeFile = (index: number) => {
        setDocFiles(prevFiles => prevFiles?.filter((_, i) => i !== index))
    }
    const { mutate: createItemFn } = useCreateVaultItem({})
    const { mutate: generateURLFn, isLoading: isLoadingSignedUrls } = useGenerateSignedUrl({
        onSuccess: data => {
            docFiles?.forEach((file, i) => {
                setSignedUrlData(prev => [
                    ...(prev ?? ([] as signedUrlContext[])),
                    {
                        signedUrl: data?.[i].signedUrl,
                        file: file,
                        id: uuidv4(),
                    },
                ])
                createItemFn({
                    name: file.file_name?.split('.')[0],
                    type: 'file',
                    path: data[i].path,
                    size: convertToBytes(file.size),
                    mimeType: `application/${file?.file_name?.split('.')[1]}`,
                    vault: vault?.id,
                    parent: formState.folder,
                })
            })
            closeModal(false)
        },
    })

    const isDisabled = !formState.folder || docFiles?.length === 0

    const handleCreate = () => {
        const filesToUpload = docFiles?.map(file => ({
            fileName: file.file_name,
            contentType: `application/${file?.file_name?.split('.')[1]}`,
        }))
        generateURLFn({
            files: filesToUpload,
            module: 'vault',
        })
    }

    const { data } = useGetVaultItems({
        queryParams: {
            vault: vault?.id as string,
            parent: vault?.id as string,
        },
    })

    return (
        <ModalWrapper variants={undefined} formId='fileForm' closeModal={() => closeModal(false)} shouldConfirmClose>
            <main className=' w-screen lg:w-[31rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium ml-5'>Add new file</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <form id='fileForm' onSubmit={e => e.preventDefault()}>
                    <div className='px-4 lg:px-7 p-7 pb-1 pt-5 overflow-y-scroll'>
                        <div className='flex flex-col flex-1 '>
                            <label className='mb-3' htmlFor='folder'>
                                Choose a folder to save it in
                            </label>
                            <Select
                                name='folder'
                                onValueChange={value => {
                                    setFormState(prevState => ({
                                        ...prevState,
                                        folder: value,
                                    }))
                                }}
                                value={formState.folder}
                            >
                                <SelectTrigger className=' text-base h-[3rem]  text-[#5E5E5E] border-gray3 flex '>
                                    {formState?.folder ? <SelectValue /> : <span>Select root folder</span>}
                                </SelectTrigger>
                                <SelectContent className='max-h-[12rem] overflow-y-scroll w-[27.5rem]'>
                                    <SelectItem value={vault?.id as string} className='pl-3'>
                                        <div className='flex items-center gap-3'>
                                            <FolderIcon className={`w-4 h-4`} />
                                            <div className='flex flex-col items-start'>
                                                <h6 className='text sm'>Root Vault</h6>
                                            </div>
                                        </div>
                                    </SelectItem>
                                    {data
                                        ?.filter(item => item.type === 'folder')
                                        .map(num => (
                                            <SelectItem value={num?.id} className='pl-3' key={num?.id}>
                                                <div className='flex items-center gap-3'>
                                                    <FolderIcon className={`w-4 h-4`} />
                                                    <div className='flex flex-col items-start'>
                                                        <h6 className='text sm'>{num?.name}</h6>
                                                    </div>
                                                </div>
                                            </SelectItem>
                                        ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className='flex flex-col gap-3 my-5'>
                            <label className='flex items-center gap-1'>Select file(s)</label>
                            <div
                                className={`flex flex-col gap-2 border border-[#DADCE0] rounded-lg px-2 lg:px-4 p-4 max-h-[180px] overflow-y-scroll`}
                            >
                                <label htmlFor='file_input' className='cursor-pointer'>
                                    {docFiles?.length === 0 ? (
                                        <div className='text-[#838383] text-sm font-semibold text-center'>
                                            Click here to upload one or more files
                                            <br />
                                            <p className='mt-1 text-[10px] font-medium text-gray7'>
                                                DOCX, PDF, XLSX, PNG files only | 2MB max file
                                            </p>
                                        </div>
                                    ) : (
                                        <p className='text-[10px] w-fit px-2 py-1 bg-[#EFEFF9] rounded-lg -mt-2'>
                                            add more files
                                        </p>
                                    )}
                                    <input
                                        type='file'
                                        name='files'
                                        id='file_input'
                                        hidden
                                        multiple
                                        ref={docRef}
                                        onChange={uploadDocFn}
                                    />
                                </label>

                                {docFiles && docFiles?.length > 0 && (
                                    <div className='flex flex-col gap-3'>
                                        {docFiles?.map((docFile, index) => (
                                            <div
                                                key={index}
                                                className='bg-white flex justify-between items-center rounded-lg'
                                            >
                                                <div className='flex items-center gap-4'>
                                                    <div className='text-xs font-semibold'>
                                                        <h2 className='text-[#202020] truncate w-[12rem] lg:w-[15.5rem]'>
                                                            {docFile.file_name}
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className='flex gap-2 text-[10px]'>
                                                    <p className='text-gray7'>{docFile.size}</p> |{' '}
                                                    <p
                                                        className='text-brand font-semibold cursor-pointer'
                                                        onClick={() => {
                                                            removeFile(index)
                                                            docRef.current?.click()
                                                        }}
                                                    >
                                                        Change
                                                    </p>{' '}
                                                    |{' '}
                                                    <span className='cursor-pointer' onClick={() => removeFile(index)}>
                                                        <CancelIcon className='text-red h-4 w-4 !stroke-[#872D10]' />
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
                <div className='flex items-center border-t border-[#DADCE0] p-4 justify-between'>
                    <>&nbsp;</>
                    <Button onClick={handleCreate} loading={isLoadingSignedUrls} disabled={isDisabled}>
                        Upload files
                    </Button>
                </div>
            </main>
        </ModalWrapper>
    )
}

// const categories = ['Contracts', 'Receipts', 'SLAs', 'Invoices', 'Bills', 'Others']
const convertToBytes = (sizeString: string): number => {
    const [size, unit] = sizeString.split(' ')

    const sizeInMB = parseFloat(size)

    if (unit === 'MB') {
        return sizeInMB * 1024 * 1024
    }
    if (unit === 'KB') {
        return sizeInMB * 1024
    }
    return 1
}
