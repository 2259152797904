import { BudgetsContextProvider } from '@/context/budget'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { budgetSidebarPermissions } from '@/layout/LayoutComponents/utils'
import NotAuthorized from '@/layout/NotAuthorized'
import Budgets from '@/modules/dashboard/Budget/Budgets'
import BudgetDetails from '@/modules/dashboard/Budget/partials/BudgetDetails'
import { Outlet, Route } from 'react-router-dom'

export const budgetRoutes = {
    baseUrl: 'budget',
    quickActions: 'budget/quick_actions',
    budgetId: ':budget_id',
}

export const useBudgetRoutes = () => {
    const hasPermission = useHasAccessPermisson(budgetSidebarPermissions)

    if (!hasPermission) return <Route path='not-authorized' element={<NotAuthorized />} />
    return (
        <Route
            element={
                <BudgetsContextProvider>
                    <Outlet />
                </BudgetsContextProvider>
            }
        >
            <Route path={budgetRoutes.baseUrl}>
                <Route index element={<Budgets />} />
                <Route path={budgetRoutes.quickActions} element={<Budgets />} />
                <Route path={budgetRoutes.budgetId} element={<BudgetDetails />} />
            </Route>
        </Route>
    )
}
