import { ChevronLeft, ChevronRight } from 'lucide-react'
import { useRef, useState } from 'react'
import { VaultCategories } from './VaultCategories'
import { VaultStorage } from './VaultStorage'
import { VaultPermission } from './VaultPermission'

export const VaultSettings = () => {
    const [step, setStep] = useState(1)
    const scrollRef = useRef<HTMLDivElement>(null)
    const scroll = (scrollOffset: number) => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += scrollOffset
        }
    }

    return (
        <section className={` pb-6`}>
            <div className='block lg:flex justify-between mb-6 lg:mb-0 relative'>
                <div className='mb-2 lg:mb-6'>
                    <h1 className='text-dark font-medium text-[20px]'>Settings</h1>
                    <p className='text-gray7 text-base'>Adjust vault settings</p>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row gap-6 justify-between mt-6'>
                <div
                    className={`lg:w-[23%] bg-white rounded-xl lg:px-7 px-2 py-7 flex gap-1 justify-between  items-center h-fit`}
                >
                    <ChevronLeft size={30} onClick={() => scroll(-100)} className='lg:hidden' />
                    <div className=' flex flex-row lg:flex-col overflow-x-scroll gap-6 w-4/5 lg:w-full' ref={scrollRef}>
                        {['Categories', 'Storage plan & usage', 'Permission'].map((data, idx) => (
                            <button
                                onClick={() => setStep(idx + 1)}
                                className={`text-left ${step === idx + 1 ? 'text-brand font-semibold' : 'font-medium text-gray7'}`}
                            >
                                {data}
                            </button>
                        ))}
                    </div>
                    <ChevronRight size={30} onClick={() => scroll(100)} className='lg:hidden' />
                </div>

                <div className='bg-white px-5 md:px-6 p-5 rounded-xl lg:w-[75%] overflow-y-scroll '>
                    {step === 1 && <VaultCategories />}
                    {step === 2 && <VaultStorage />}
                    {step === 3 && <VaultPermission />}
                </div>
            </div>
        </section>
    )
}
