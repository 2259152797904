import { isBefore } from 'date-fns'

export const getPayDay = (data: number | number[]) => {
    // console.log({ data })
    const date_1 = Array.isArray(data) ? data[0] : data
    const date_2 = Array.isArray(data) ? data[1] : undefined

    // if (typeof data === 'string') {
    //     return data
    // }
    const today = new Date()
    const pay_day_1 = new Date(today.getFullYear(), today.getMonth(), date_1)
    const pay_day_2 = new Date(today.getFullYear(), today.getMonth(), date_2)

    // console.log(date_1)
    // console.log(date_2)

    if (!isBefore(today, pay_day_1) && !isBefore(today, pay_day_2)) {
        return { closest: date_2 ?? date_1, next: date_1 ?? date_2 }
    }

    return { closest: date_1 ?? date_2, next: date_2 ?? date_1 }
}

export const getDayOfWeek = (data: string) => {
    const today = new Date()

    const days_of_week = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
    }
    const to_day = days_of_week[data.toLowerCase() as keyof typeof days_of_week]

    today.setDate(today.getDate() - today.getDay() + to_day)
    return { last_pay_day: today, day_in_words: data }
}

export const formatDate = (number: any) => {
    if (number > 3 && number < 21) return 'th'
    switch (number % 10) {
        case 1:
            return 'st'
        case 2:
            return 'nd'
        case 3:
            return 'rd'
        default:
            return 'th'
    }
}

export const payrollGenerationDays = [
    {
        name: '5 days before pay day',
        id: '5',
    },
    {
        name: '4 days before pay day',
        id: '4',
    },

    {
        name: '3 days before pay day',
        id: '3',
    },

    {
        name: '2 days before pay day',
        id: '2',
    },

    {
        name: '1 day before pay day',
        id: '1',
    },
]

export const payrollGenerationType = [
    { name: 'Automatic', id: 'auto' },
    { name: 'Manual', id: 'manual' },
]

export const leaveDays = {
    sickDays: [
        { name: ' 5 days', id: '5' },
        { name: ' 6 days', id: '6' },
        { name: ' 7 days', id: '7' },
        { name: ' 8 days', id: '8' },
        { name: ' 9 days', id: '9' },
        { name: ' 10 days', id: '10' },
        { name: ' 11 days', id: '11' },
        { name: ' 12 days', id: '12' },
    ],
    childSupport: [
        { name: ' 1 Week', id: '7' },
        { name: ' 2 Weeks', id: '14' },
        { name: ' 3 Weeks', id: '21' },
        { name: ' 1 Month', id: '30' },
        { name: ' 2 Months', id: '60' },
        { name: ' 3 Months', id: '90' },
        { name: ' 4 Months', id: '120' },
        { name: ' 5 Months', id: '150' },
        { name: ' 6 Months', id: '180' },
        { name: ' 1 Year', id: '360' },
    ],
    personal: [
        { name: ' 1 day', id: '1' },
        { name: ' 2 days', id: '2' },
        { name: ' 3 days', id: '3' },
        { name: ' 4 days', id: '4' },
        { name: ' 5 days', id: '5' },
    ],
    vacation: [
        { name: ' 10 days', id: '10' },
        { name: ' 11 days', id: '11' },
        { name: ' 12 days', id: '12' },
        { name: ' 13 days', id: '13' },
        { name: ' 14 days', id: '14' },
        { name: ' 15 days', id: '15' },
        { name: ' 16 days', id: '16' },
        { name: ' 17 days', id: '17' },
        { name: ' 18 days', id: '18' },
        { name: ' 19 days', id: '19' },
        { name: ' 20 days', id: '20' },
        { name: ' 21 days', id: '21' },
        { name: ' 22 days', id: '22' },
        { name: ' 23 days', id: '23' },
        { name: ' 24 days', id: '24' },
        { name: ' 25 days', id: '25' },
    ],
}
