import { Button } from '@/components'
import { useNavigate } from 'react-router-dom'
import ModalWrapper from '@/components/Modals/ModalWrapper'

const RedirectModal = () => {
    const navigate = useNavigate()
    return (
        <ModalWrapper>
            <main className='h-[14rem] w-[28rem]'>
                <div className='pt-10'>
                    <p className='text-[#5E5E5E] font-semibold text-lg mb-10 w-[85%] mx-auto'>
                        Thank you very much. The end user has been notified of the payment.
                    </p>
                    <div className='flex gap-5 justify-end pr-10'>
                        <Button
                            className=' text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                            onClick={() => navigate('/dashboard/overview')}
                        >
                            Go Home
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default RedirectModal
