import { ProfileEditIcon } from '@/assets/assets.export'
import { Button } from '@/components'
import SelectWIthChip from '@/components/SelectWithChip'
import { useAuthContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { capitalizeText } from '@/utils/string'
import { useEffect, useState } from 'react'

export const VaultPermission = () => {
    const [changePermissions, setChangePermissions] = useState(false)
    const [orgMembersData, setOrgMembersData] = useState<{ label: string; value: string }[]>([])
    const [selectedMembers, setSelectedMembers] = useState<{ label: string; value: string }[]>([])

    const { selectedAccount } = useAuthContext()

    const { data } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    useEffect(() => {
        if (data) {
            setOrgMembersData(
                data.map(member => ({
                    label: `${capitalizeText(member?.user?.first_name)} ${capitalizeText(member?.user?.last_name)}`,
                    value: member?.id,
                }))
            )
        }
    }, [data])
    return (
        <div>
            <div className='flex justify-between gap-3'>
                <div className=''>
                    <h1 className='text-dark font-medium text-lg'>Permission</h1>
                    <p className='text-gray7 text-sm'>
                        Manage who can view, edit, and control documents in the Vault by assigning permissions to
                        admins.
                    </p>
                </div>
                {!changePermissions && (
                    <span
                        className='flex items-center gap-3 h-fit py-2 text-[#5E5E5E] border-[#DADCE0] rounded-md p-0 border px-2 cursor-pointer w-fit'
                        onClick={() => {
                            setChangePermissions(true)
                        }}
                    >
                        <ProfileEditIcon />
                        <button className='text-sm py-0' type='button'>
                            Edit
                        </button>
                    </span>
                )}
            </div>
            {!changePermissions && (
                <div className='mt-4'>
                    <p className='px-2 py-1 bg-[#DEB145] rounded-lg w-fit'>You</p>
                    <div className='mt-3 flex gap-4'>
                        {names.map(name => (
                            <p className='bg-[#EFEFF9] px-3 py-2 rounded-xl'>{name}</p>
                        ))}
                    </div>
                </div>
            )}
            {changePermissions && (
                <div className='mt-4'>
                    <p className='mb-2 text-gray7 font-medium'>Select members</p>
                    <SelectWIthChip
                        options={orgMembersData}
                        placeholder='Click to see users'
                        onChange={members_details => {
                            setSelectedMembers(members_details)
                        }}
                        value={selectedMembers}
                        className='w-2/5'
                    />
                    <div className='flex justify-end mt-3'>
                        <Button className='' onClick={() => setChangePermissions(false)}>
                            Save changes
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

const names = ['Amara Onyeabuchi', 'Abubakar Kazeem', 'Michael Odutola']
