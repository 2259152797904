import { BizpendAccounts } from '@/assets/assets.export'
import loader from '@/assets/loadericon.svg'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useAuthContext } from '@/context'
import { useGetDedicatedBankAccounts } from '@/services/accounts/queries'
import { keyQueryOrgExpenses } from '@/services/expenses/queries'
import { UserAccount } from '@/services/payout/types'
import { useMutateReimburse } from '@/services/reimburse/mutations'
import { ACCOUNT_PAYABLE_FROM, DedicatedBankAccountType } from '@/types/accounts'
import { ExpenseType } from '@/types/expense'
import { formatAmount } from '@/utils/money'
import { useMemo, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../../ModalWrapper'

interface ReimburseExpenseModalProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    accounts: UserAccount[]
    expense: ExpenseType
}

type Props = {
    actions: () => void
    expense: ExpenseType
}

const ReimburseExpenseModal = ({ setShowModal, accounts, expense }: ReimburseExpenseModalProps) => {
    const bank_details = accounts?.filter(bankDetails => bankDetails.is_primary) ?? []

    const [accountId, setAccountId] = useState(ACCOUNT_PAYABLE_FROM.Wallet as string)

    const [reimbursed, setReimbursed] = useState(false)
    const { selectedAccount } = useAuthContext()

    const { mutate, isLoading: isReimbursing } = useMutateReimburse({
        queryParams: {
            org_account_id: selectedAccount?.id as string,
        },
        onSuccess: () => {
            // Close the modal
            setReimbursed(true)
        },
        refetch: [['/expense-id'], keyQueryOrgExpenses],
    })

    const { data } = useGetDedicatedBankAccounts({
        queryParams: { entity_id: selectedAccount?.org?.id as string },
    })
    const bankAccounts = useMemo(() => data ?? ([] as DedicatedBankAccountType[]), [data])

    const handleReimbursement = () => {
        const isWallet = accountId === ACCOUNT_PAYABLE_FROM.Wallet
        mutate({
            bank_account: bank_details?.[0].id,
            expense_id: expense.id,
            source_type: isWallet ? ACCOUNT_PAYABLE_FROM.Wallet : ACCOUNT_PAYABLE_FROM.DepositAccount,
            source_id: isWallet ? undefined : accountId,
        })
    }

    const expenseDetails = [
        {
            name: 'Expense ID',
            desc: expense?.expense_id,
        },
        {
            name: 'Recipient ',
            desc: `${expense?.submitter?.user?.first_name ?? 'N/A'} ${expense?.submitter?.user?.last_name ?? ''}`,
        },
        {
            name: 'Total amount due',
            desc: formatAmount(expense?.amount),
        },

        {
            name: 'Category',
            desc: expense?.category?.label,
        },
    ]

    return (
        <ModalWrapper variants={undefined}>
            {reimbursed ? (
                <ReimbursementSuccessful
                    actions={() => {
                        setShowModal(false)
                        setReimbursed(false)
                    }}
                    expense={expense}
                />
            ) : isReimbursing ? (
                <div className='w-screen lg:w-[30rem] h-[350px] flex items-center justify-center gap-4'>
                    <p className='text-xl font-semibold text-[#5E5E5E]'>Initializing reimbursement</p>
                    <img src={loader} className='animate-spin' alt='loader' />
                </div>
            ) : (
                <>
                    <div className='w-screen lg:w-[30rem] max-h-[90vh] overflow-scroll'>
                        <div className='border-b border-[#DADCE0] py-4 px-4  '>
                            <div className='flex items-center  justify-between h-full'>
                                <h2 className='font-semibold text-[#31254B] text-xl'>Reimburse expense</h2>
                                <div
                                    className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                                    onClick={() => setShowModal(false)}
                                >
                                    <AiOutlineClose fontSize={25} />
                                </div>
                            </div>
                        </div>
                        <div className='px-[1.1rem] my-4'>
                            <h3 className='text-base text-gray7 font-medium'>
                                Reimburse this expense - {expense?.expense_id}
                            </h3>
                            <div className='bg-[#EFEFF9] rounded-lg p-5 space-y-2 mt-4 font-medium'>
                                {expenseDetails?.map((details, idx) => (
                                    <div className='flex justify-between' key={idx}>
                                        <span className='text-gray7 text-sm'>{details?.name}</span>
                                        <span>{details?.desc}</span>
                                    </div>
                                ))}
                            </div>

                            <fieldset className='mt-5 flex flex-col flex-1 '>
                                <label className='mb-1.5 text-sm' htmlFor='funding_method'>
                                    Pay with this account
                                </label>
                                <Select name='account' onValueChange={setAccountId} defaultValue='Wallet'>
                                    <SelectTrigger
                                        className={`text-base ${accountId ? 'h-[3.5rem]' : 'h-[3rem]'} border-gray3 flex `}
                                    >
                                        <SelectValue placeholder={'Select  account'} />
                                    </SelectTrigger>
                                    <SelectContent className='h-56'>
                                        <SelectItem value={'Wallet'} className='pl-3'>
                                            <div className='flex items-center gap-3'>
                                                <BizpendAccounts
                                                    className={`w-8 h-8 rounded-lg  fill-green bg-green/10 p-1.5`}
                                                />
                                                <div className='flex flex-col items-start'>
                                                    <h6 className='text sm'>Wallet</h6>
                                                </div>
                                            </div>
                                        </SelectItem>
                                        {bankAccounts?.map(bank => (
                                            <SelectItem
                                                key={bank?._id}
                                                value={bank?._id}
                                                className='pl-3'
                                                // disabled={Number(expense?.amount) > bank?.balance}
                                            >
                                                <div className='flex items-center gap-3'>
                                                    <BizpendAccounts
                                                        className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`}
                                                    />
                                                    <div className='flex flex-col items-start'>
                                                        <h6 className='text sm'>
                                                            {bank?.nickname ?? bank?.account_name}
                                                        </h6>
                                                        <p className='text-xs text-gray1 font-medium'>
                                                            {formatAmount(bank?.balance)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </fieldset>
                        </div>
                        <div className='px-[1.8rem] mb-3 mt-7'>
                            <Button className='ml-auto' onClick={handleReimbursement} loading={isReimbursing}>
                                Proceed
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </ModalWrapper>
    )
}

const ReimbursementSuccessful = ({ actions, expense }: Props) => {
    return (
        <div className=''>
            <div
                className='bg-[#F2F2F2] w-[30px] h-[30px] mt-6 ml-auto mr-6 p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                onClick={() => {
                    actions()
                }}
            >
                <AiOutlineClose fontSize={25} />
            </div>
            <div className='w-screen lg:w-[40rem] h-[350px] flex flex-col items-center justify-end gap-4 py-20'>
                <div>
                    <svg
                        className='checkmark w-[56px] h-[56px] rounded-full block'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 52 52'
                    >
                        <circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />{' '}
                        <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
                    </svg>
                </div>
                <h1 className='text-lg font-semibold text-[#202020]'>Reimbursement successful</h1>
                <p className='text-center'>
                    Congratulations!{' '}
                    <span className='font-semibold'>
                        {expense?.submitter?.user?.first_name ?? 'N/A'} {expense?.submitter?.user?.last_name ?? ''}
                    </span>{' '}
                    has
                    <br /> been successfully reimbursed.
                </p>
            </div>
        </div>
    )
}

export default ReimburseExpenseModal
