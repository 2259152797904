import {
    ContractPayrollType,
    LeaveSettingsType,
    OrgContractPayHistory,
    OrgPayrollHistoryType,
    PaymentProfile,
    UserTypeLeave,
} from '@/types/payroll'
import { createQuery } from '../api/query'
import {
    LeaveRequestType,
    PayrollListType,
    QueryLeaveAnalytics,
    QueryOrgContractPayRollType,
    QueryOrgPayrollByDateType,
    QueryOrgPayrollType,
    QueryPaymentProfile,
    QueryPayrollAnalytics,
    QueryPayrollOrgSettings,
    QueryPayrollTopEarners,
    QueryUserPayrollHistoryType,
} from './types'

export const orgPayRollKey = ['org-payroll']
export const orgPayRollByDateKey = ['org-payroll-by-date']
export const orgPayRollById = ['org-payroll-by-id']
export const orgPayRollByPayDay = ['org-payroll-by-day']
export const orgLeaveRequests = ['get-leave-requests']
export const orgPayrollSettingsKey = ['org-payroll-settings']
export const getuserPayHistoryKey = ['get-user-payroll-history']
export const getuserLeaveHistoryKey = ['get-user-leave-history']
export const getOrgLeaveSettings = ['get-leave-settings']
export const getOrgPayrollHistory = ['get-org-payroll-history']
export const getuserPayHistoryByIDKey = (arg: string) => ['get-user-payroll-history', arg]
export const getOrgContractPayroll = ['get-org-contract-payroll']
export const getOrgContractPayrollByIDKey = (arg: string) => ['get-org-contract-payroll-by-id', arg]
export const getLeaveAnalytics = ['get-leave-analytics']
export const orgPayrollListKey = ['org-payroll-list']

export const useGetOrgPayrolls = createQuery<QueryOrgPayrollType>({
    url: 'get-payment-profiles-for-org',
    key: orgPayRollKey,
})
export const useGetPayrollByDate = createQuery<QueryOrgPayrollByDateType[]>({
    url: 'group-payroll-by-date',
    key: orgPayRollByDateKey,
})

export const useGetPayrollByID = createQuery<QueryOrgPayrollByDateType[]>({
    url: 'get-payroll-by-id',
    key: orgPayRollById,
})

export const useGetPayrollByPayday = createQuery<QueryOrgPayrollByDateType>({
    url: 'get-payroll-by-pay-day',
    key: orgPayRollById,
})

export const useGetPayrollListForOrg = createQuery<PayrollListType>({
    url: 'payroll-for-org',
    key: orgPayrollListKey,
})

export const useGetLeaveRequests = createQuery<LeaveRequestType[]>({
    url: 'get-leaves-for-org',
    key: orgLeaveRequests,
})

export const getLeaveByIdKey = (arg: string) => ['get-leave-by-id', 'get-user-leave-history', arg]

export const useGetLeaveById = createQuery<LeaveRequestType>({
    url: 'get-leave-by-id',
    key: getLeaveByIdKey,
})
export const useGetPayrollSettingsForOrg = createQuery<QueryPayrollOrgSettings>({
    url: 'get-payroll-setting-for-org',
    key: orgPayrollSettingsKey,
})

export const useGetUserPayrollHistory = createQuery<QueryUserPayrollHistoryType>({
    url: 'get-user-payroll-history',
    key: getuserPayHistoryKey,
})
export const useGetUserPayrollHistoryById = createQuery<PaymentProfile>({
    url: 'get-payroll-history-by-id',
    key: getuserPayHistoryByIDKey,
})

export const useGetUserLeaveHistory = createQuery<UserTypeLeave[]>({
    url: 'get-user-leave-requests',
    key: getuserLeaveHistoryKey,
})

export const useGetLeaveSettings = createQuery<LeaveSettingsType[]>({
    url: 'get-leave-setting-for-org',
    key: getOrgLeaveSettings,
})

export const useGetorgPayrollHistory = createQuery<OrgPayrollHistoryType[]>({
    url: 'get-payroll-history-for-org',
    key: getOrgPayrollHistory,
})
export const useGetorgContractPayrollHistory = createQuery<OrgContractPayHistory>({
    url: 'get-contract-pay-history',
    key: getOrgPayrollHistory,
})

export const useGetOrgContractPayroll = createQuery<QueryOrgContractPayRollType>({
    url: 'get-contract-payrolls-for-org',
    key: getOrgContractPayroll,
})

export const useGetOrgContractPayrollById = createQuery<ContractPayrollType>({
    url: 'get-contract-by-id',
    key: getOrgContractPayrollByIDKey,
})

export const useGetPaymentProfile = createQuery<QueryPaymentProfile>({
    url: 'get-payment-profile',
    key: [],
})

export const useGetLeaveAnalytics = createQuery<QueryLeaveAnalytics>({
    url: 'get-leave-analytics',
    key: getLeaveAnalytics,
})

export const useGetPayrollAnalytics = createQuery<QueryPayrollAnalytics>({
    url: 'get-payroll-analytics',
    key: ['get-payroll-analytics'],
})

export const useGetTopPayrollEarners = createQuery<QueryPayrollTopEarners[]>({
    url: 'top-payroll-earners',
    key: ['top-payroll-earners'],
})

export const useGetPyrollByPeriod = createQuery<OrgPayrollHistoryType>({
    url: 'payroll-history-by-period',
    key: ['payroll-history-by-period'],
})

export const useGetEligibleEmployees = createQuery<PaymentProfile[]>({
    url: 'eligible-payroll-employees',
    key: ['eligible-payroll-employees'],
})

export const useGetUpcomingLeave = createQuery<LeaveRequestType[]>({
    url: 'upcoming-leave',
    key: orgLeaveRequests,
})
