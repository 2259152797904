import React, { useRef } from 'react'
import ModalWrapper from '../ModalWrapper'
import { Button } from '@/components/Button'
import { AiOutlineClose } from 'react-icons/ai'
import { Controller, useFormContext } from 'react-hook-form'
import { Input } from '@/components/Input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { emailRegex } from '@/utils/string'
import { Percent } from 'lucide-react'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import ReactDatePicker from 'react-datepicker'
import { states_and_lgas } from '@/utils/states_and_lgas'
import { toastHandler } from '@/components/utils/Toast'
import { BizCompanyComplianceType } from '@/modules/dashboard/Settings/partials/CompanyCompliance/utils'
import { ErrorText } from '@/modules/dashboard/Settings/partials/CompanyCompliance/components/ErrorText'

type Props = {
    closeModal: () => void
    edit?: boolean
    index: number
    removeItem: () => void
}
const officersTitle = [
    'CEO',
    'COO',
    'CFO',
    'CIO',
    'President',
    'Controller',
    'Member',
    'VP',
    'Manager',
    'Partner',
    'Treasurer',
]

export const AddOfficerComplianceModal = ({ closeModal, index, edit, removeItem }: Props) => {
    const dateRef = useRef<ReactDatePicker>(null)

    const { control, watch, trigger } = useFormContext<BizCompanyComplianceType>()
    const watchOfficerNationality = watch(`officers.${index}.data.nationality`)
    const watchOfficerRole = watch(`officers.${index}.data.role`)
    const watchOfficerTitle = watch(`officers.${index}.data.title`)
    const watchOfficerCountry = watch(`officers.${index}.address.country`)
    const watchOfficerState = watch(`officers.${index}.address.state`)
    const watchOfficerLGA = watch(`officers.${index}.address.lga`)

    const handleClick = async () => {
        const isValid = await trigger(`officers.${index}`)
        if (isValid) {
            toastHandler({
                message: `Officer ${edit ? 'Updated' : 'Added'}`,
                state: 'success',
            })
            closeModal()
        }
    }

    return (
        <ModalWrapper>
            <section className='w-screen lg:w-[40rem] min-h-[69vh] max-h-[90vh] overflow-scroll relative bg-[#F9F9F9] rounded-lg'>
                <div className='flex items-center justify-between w-full border-b border-gray5 py-3 px-2  sticky top-0 bg-white z-30'>
                    <h2 className='font-semibold text-black/90 flex-1 text-center'>
                        {edit ? 'Update' : 'Add'} Officer
                    </h2>
                    <div
                        className='bg-gray5 w-6 h-6 p-2 rounded-full flex items-center justify-center text-gray7 cursor-pointer'
                        onClick={() => {
                            removeItem()
                            closeModal()
                        }}
                    >
                        <AiOutlineClose fontSize={30} />
                    </div>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-2 p-6 gap-6 overflow-y-scroll mb-16'>
                    <Controller
                        name={`officers.${index}.fullName.firstName`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label
                                    htmlFor={`officers.${index}.fullName.firstName`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    First Name
                                </label>
                                <Input
                                    {...field}
                                    type='text'
                                    placeholder='Enter First name'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.fullName.lastName`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label
                                    htmlFor={`officers.${index}.fullName.lastName`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    Last Name
                                </label>
                                <Input
                                    {...field}
                                    type='text'
                                    placeholder='Enter Last name'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.data.role`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                <label
                                    htmlFor={`officers.${index}.data.role`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    Roles
                                </label>
                                <Select
                                    {...field}
                                    name={`officers.${index}.data.role`}
                                    onValueChange={value => {
                                        field.onChange(value)
                                    }}
                                >
                                    <SelectTrigger className='h-12 border-gray5 text-sm'>
                                        {!watchOfficerRole && <span className='text-gray1'>Select role</span>}
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                        <SelectItem value={'OWNER'}>Owner</SelectItem>
                                        <SelectItem value={'DIRECTOR'}>Director</SelectItem>
                                    </SelectContent>
                                </Select>
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.data.nationality`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                <label
                                    htmlFor={`officers.${index}.data.nationality`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    Nationality
                                </label>
                                <Select
                                    {...field}
                                    name={`officers.${index}.data.nationality`}
                                    onValueChange={field.onChange}
                                >
                                    <SelectTrigger className='h-12 border-gray5 text-sm'>
                                        {!watchOfficerNationality && (
                                            <span className='text-gray1'>Select Nationality</span>
                                        )}
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                        <SelectItem value={'NG'}>Nigerian</SelectItem>
                                    </SelectContent>
                                </Select>
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.data.email`}
                        rules={{
                            required: true,
                            pattern: emailRegex,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label
                                    htmlFor={`officers.${index}.data.email`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    Email
                                </label>
                                <Input
                                    {...field}
                                    type='email'
                                    placeholder='Enter email address'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.data.phoneNumber`}
                        rules={{
                            required: true,
                            pattern: /^\d{11}$/,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label
                                    htmlFor={`officers.${index}.data.phoneNumber`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    Phone number
                                </label>
                                <Input
                                    {...field}
                                    type='number'
                                    placeholder='Enter phone number'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {error && <ErrorText message='Please enter a valid 11-digit number.' />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.data.bvn`}
                        rules={{
                            required: true,
                            pattern: /^\d{11}$/,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label
                                    htmlFor={`officers.${index}.data.bvn`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    BVN number
                                </label>
                                <Input
                                    {...field}
                                    type='number'
                                    placeholder='Enter bvn number'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {error && <ErrorText message='Please enter a valid 11-digit number.' />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.data.title`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                <label
                                    htmlFor={`officers.${index}.data.title`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    Title
                                </label>
                                <Select {...field} name={`officers.${index}.data.title`} onValueChange={field.onChange}>
                                    <SelectTrigger className='h-12 border-gray5 text-sm'>
                                        {!watchOfficerTitle && <span className='text-gray1'>Select title</span>}
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                        {officersTitle.map(title => (
                                            <SelectItem value={title} key={title}>
                                                {title}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.data.percentageOwned`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label
                                    htmlFor={`officers.${index}.data.percentageOwned`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    Percentage
                                </label>
                                <Input
                                    leftIcon={<Percent />}
                                    className='h-12 border-gray5'
                                    min={0}
                                    value={formatAmount(field?.value).replaceAll('₦', '')}
                                    onChange={e => {
                                        const value_entered = formatAmountWithoutSymbols(e.target.value)
                                        if (Number(value_entered) > 10000) return
                                        field.onChange(value_entered)
                                    }}
                                />
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.data.dateOfBirth`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-2 '>
                                <label htmlFor={`officers.${index}.dob`} className='text-gray7 font-normal text-sm '>
                                    Date of birth
                                </label>
                                <CustomDatePicker
                                    ref={dateRef}
                                    placeholderText='Select Date'
                                    onChange={field.onChange}
                                    selected={field.value as unknown as Date}
                                    value={field.value as unknown as string}
                                    dateFormatCalendar='yyyy'
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    className=' w-52'
                                    dropdownMode='select'
                                />
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.address.country`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                <label
                                    htmlFor={`officers.${index}.address.country`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    Country
                                </label>
                                <Select
                                    {...field}
                                    name={`officers.${index}.address.country`}
                                    onValueChange={field.onChange}
                                >
                                    <SelectTrigger className='h-12 border-gray5 text-sm'>
                                        {!watchOfficerCountry && <span className='text-gray1'>Select Country</span>}
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                        <SelectItem value={'NG'}>Nigeria</SelectItem>
                                    </SelectContent>
                                </Select>
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.address.state`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                <label
                                    htmlFor={`officers.${index}.address.state`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    State
                                </label>
                                <Select
                                    {...field}
                                    name={`officers.${index}.address.state`}
                                    onValueChange={field.onChange}
                                >
                                    <SelectTrigger className='h-12 border-gray5 text-sm'>
                                        {!watchOfficerState && <span className='text-gray1'>Select State</span>}
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                        {states_and_lgas.map(state => (
                                            <SelectItem value={state.alias} key={state.alias}>
                                                {state.state}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.address.lga`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                <label
                                    htmlFor={`officers.${index}.address.lga`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    LGA
                                </label>
                                <Select
                                    {...field}
                                    name={`officers.${index}.address.lga`}
                                    onValueChange={field.onChange}
                                    disabled={!watchOfficerState}
                                >
                                    <SelectTrigger className='h-12 border-gray5 text-sm'>
                                        {!watchOfficerLGA && <span className='text-gray1'>Select LGA</span>}
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                        {states_and_lgas
                                            .filter(state => state.alias === watchOfficerState)[0]
                                            ?.lgas.map(lga => <SelectItem value={lga}>{lga}</SelectItem>)}
                                    </SelectContent>
                                </Select>
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.address.city`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label
                                    htmlFor={`officers.${index}.address.city`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    City
                                </label>
                                <Input
                                    {...field}
                                    placeholder='Enter closest landmark'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.address.postalCode`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label
                                    htmlFor={`officers.${index}.address.postalCode`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    Postal code
                                </label>
                                <Input
                                    {...field}
                                    placeholder='Enter postal code'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name={`officers.${index}.address.addressLine_1`}
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label
                                    htmlFor={`officers.${index}.address.addressLine_1`}
                                    className='text-gray7 font-normal text-sm mb-1'
                                >
                                    Street address
                                </label>
                                <Input
                                    {...field}
                                    placeholder='Enter address'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {error && <ErrorText />}
                            </div>
                        )}
                    />
                </div>
                <div>
                    <div className='bg-white fixed bottom-0 border-t border-gray8 w-full flex items-center justify-end gap-4  px-6 py-2 rounded-b-md'>
                        <Button
                            onClick={handleClick}
                            type={'submit'}
                            className='bg-brand text-white px-8 h-12 rounded-lg text-sm min-w-[130px]'
                        >
                            {edit ? 'Update' : 'Add'} Officer
                        </Button>
                    </div>
                </div>
            </section>
        </ModalWrapper>
    )
}
